import React from "react";
import styles from "./caseStudy.module.scss";
import Section from "../../components/Wrappers/Section";
import Wrapper from "../../components/Wrappers/Wrapper";
import CaseStudyCard from "../../components/CaseStudyCard/CaseStudyCard";

import Kafene from "../../assets/imgs/casestudy-kafene.jpg";
import CareClick from "../../assets/imgs/casestudy-careclick.jpg";
import Volley from "../../assets/imgs/casestudy-volley.jpg";
import Ficlub from "../../assets/imgs/casestudy-ficlub.jpg";
import Letshego from "../../assets/imgs/casestudy-letshego.jpg";
import Presmit from "../../assets/imgs/casestudy-presmit.jpg";
import Peeamo from "../../assets/imgs/casestudy-peeamo.jpg";
import Hicolumn from "../../assets/imgs/casestudy-hicolumn.jpg";
import Kwerty from "../../assets/imgs/casestudy-kwerty.jpg";
import Whispa from "../../assets/imgs/casestudy-whispa.jpg";

const CaseStudy = () => {
  const caseStudies = [
    {
      title: "Presmit",
      info: "A user-centric platform designed for you to trade, buy and sell various digital assets including gift cards, bitcoins, USDT, litecoins, dogecoins, airtime and data topup.",
      image: Presmit,
      url: null,
    },
    {
      title: "Care click",
      info: "Customized lease options add flexibility and affordability to your life. Get the products you want without having to pay for it all upfront.",
      image: CareClick,
      url: "/case-studies/care-click",
    },
    {
      title: "Kafene",
      info: "Kafene Is a digital web and app-based lease to own platform that helps all consumers buy the things they want over time, affordably.",
      image: Kafene,
      url: "/case-studies/kafene",
    },
    {
      title: "Volley",
      info: "Volley help B2B companies talk to their prospects by scaling personalized and hyper-relevant outreach.",
      image: Volley,
      url: null,
    },

    {
      title: "Whispa",
      info: "Whispa offers end-to-end access to sexual and reproductive health information, products and services.",
      image: Whispa,
      url: null,
    },
    {
      title: "Letshego",
      info: "Delivering inclusive finance solutions to underserved populations across the Sub Saharan Market.",
      image: Letshego,
      url: null,
    },
    {
      title: "Hicolumn",
      info: "Providing businesses the opportunity to become active participants in the e-commerce ecosystem.",
      image: Hicolumn,
      url: null,
    },
    {
      title: "Spark",
      info: " Leveraging financial solutions to drive innovation, impacts and improve lifestyle.",
      image: Ficlub,
      url: null,
    },
    {
      title: "Peeamo",
      info: "Peeamo is a fun, private-dating app designed for single professionals which offers matching and messaging experiences.",
      image: Peeamo,
      url: null,
    },
    {
      title: "Kwerty",
      info: "Strengthening institutions by improving their abilities to accurately analyse, collect and visualize data.",
      image: Kwerty,
      url: null,
    },
  ];

  return (
    <Section className={styles.wrapper}>
      <Wrapper>
        <h2 className={styles.wrapper__info}>
          Some of the projects we have designed and built for our clients.
        </h2>

        <CaseStudyCard caseStudies={caseStudies} />
      </Wrapper>
    </Section>
  );
};

export default CaseStudy;
