import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import styles from "./community.module.scss";
import Section from "../../components/Wrappers/Section";
import Wrapper from "../../components/Wrappers/Wrapper";
import AuthorImage from "../../assets/imgs/author.svg";
import Pointer from "../../assets/imgs/pointer.svg";
import AboutEnyataBlog from "../../assets/imgs/blog-about-enyata.jpg";
import Introduction from "../../components/Community/Introduction";
import Landing from "../../components/Community/Landing";
import { getBlogPosts } from "../../redux/Actions/CommunityActions";
import {
  calculateReadTime,
  convertToText,
  removeSpecialChar,
} from "../../utils/functions/formatString";

const Blog = ({ blogposts, getPosts }) => {
  const [activeView, setActiveView] = useState("Blog");

  const desc =
    "Discover a wide range of resources written by experienced tech professionals at Enyata.\nNever miss a new post, stay updated by subscribing to our blog.";

  useEffect(() => {
    getPosts();
    // eslint-disable-next-line
  }, []);

  const authorImage = blogposts && blogposts.feed && blogposts.feed.image;
  const posts = blogposts && blogposts.items;
  const recentPosts = posts && posts.slice(0, 3);
  const featuredPosts = posts && posts.slice(-3);
  // const recommendedTopics = [
  //   "Software",
  //   "Product design",
  //   "Business",
  //   "Quality Assurance",
  //   "Art",
  //   "Buildup",
  //   "Product Managment",
  // ];

  // To do - Add loader

  return (
    <div className={styles.blog}>
      <Landing
        activeView={activeView}
        setActiveView={setActiveView}
        description={desc}
      />
      {blogposts ? (
        <Section className={styles.posts__section}>
          <Wrapper className={styles.posts}>
            <Introduction
              className={styles.introduction__blogs}
              title="Tech Notions"
              details="Tech Notions (by Enyata) is aimed at helping everyone get familiar with the tech space by documenting whatever is needed for them to gain knowledge."
            />
            <div className={styles.header}>
              <div className={styles.header__title}>Latest Posts</div>
            </div>
            <div className={styles.content}>
              <div className={styles.content__left}>
                {recentPosts &&
                  recentPosts.map((post) => (
                    <a
                      href={post.link}
                      target="_blank"
                      rel="noreferrer"
                      key={post.title}
                    >
                      <div className={styles.single__post}>
                        <div className={styles.single__post__content}>
                          <div className={styles.top}>
                            <div className={styles.top__image}>
                              <img src={authorImage} alt="author pic" />
                            </div>
                            <p>{post.author}</p>
                            <div className="pointer">
                              <img src={Pointer} alt="pointer" />
                            </div>
                            <span>{moment(post?.pubDate).format("ll")}</span>
                          </div>
                          <h2 className={styles.blog__title}>{post.title}</h2>
                          <p className={styles.blog__content}>{`${convertToText(
                            post?.description,
                            120
                          )}...`}</p>
                          <div className={styles.bottom}>
                            <div className={styles.tags}>
                              {post.categories &&
                                post.categories[1] &&
                                removeSpecialChar(post.categories[1])}
                            </div>
                            <span>{calculateReadTime(post?.content)}</span>
                          </div>
                        </div>
                        <div className={styles.single__post__image}>
                          {post.title.includes(
                            "About Enyata: What You Should Know"
                          ) ? (
                            <img src={AboutEnyataBlog} alt="blog pic" />
                          ) : (
                            <img src={post.thumbnail} alt="blog pic" />
                          )}
                        </div>
                      </div>
                    </a>
                  ))}
              </div>
              <div className={styles.content__right}>
                {/* <div className={styles.content__right__header}>
                  <div className={styles.title}>Recommended Topic</div>
                </div>
                <div className={styles.tags}>
                  {recommendedTopics.map((topic, index) => (
                    <div className={styles.single__tag} key={index}>
                      {topic}
                    </div>
                  ))}
                </div> */}
              </div>
            </div>
          </Wrapper>

          <Wrapper className={styles.posts}>
            <div className={styles.header}>
              <div className={styles.header__title}>Featured Posts</div>
            </div>
            <div className={styles.featured}>
              {featuredPosts &&
                featuredPosts.map((post) => (
                  <a
                    href={post.link}
                    target="_blank"
                    rel="noreferrer"
                    key={post.title}
                  >
                    <div className={styles.featured__post}>
                      <div className={styles.featured__image}>
                        <img src={post.thumbnail} alt="post pic" />
                      </div>
                      <div className={styles.featured__content}>
                        <div className={styles.top}>
                          <div className={styles.top__image}>
                            <img src={AuthorImage} alt="author pic" />
                          </div>
                          <p>{post.author}</p>
                          <div className="pointer">
                            <img src={Pointer} alt="pointer" />
                          </div>
                          <span>{moment(post?.pubDate).format("MMM, D")}</span>
                        </div>
                        <h2 className={styles.blog__title}>{post.title}</h2>
                        <p className={styles.blog__content}>{`${convertToText(
                          post?.description,
                          150
                        )}...`}</p>
                        <div className={styles.bottom}>
                          <div className={styles.tags}>
                            {post.categories &&
                              post.categories[1] &&
                              removeSpecialChar(post.categories[1])}
                          </div>
                          <span>{calculateReadTime(post?.content)}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
            </div>
          </Wrapper>
        </Section>
      ) : (
        <Section className={styles.posts__section}>
          <Wrapper className={styles.posts}>
            <Introduction
              className={styles.introduction__blogs}
              title="Tech Nations"
              details="Tech Notions (by Enyata) is aimed at helping everyone get familiar with the tech space by documenting whatever is needed for them to gain knowledge."
            />

            <div>No blog posts available</div>
          </Wrapper>
        </Section>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.community.isLoading,
  success: state.community.success,
  blogposts: state.community.posts,
});

const mapDispatchToProps = (dispatch) => ({
  getPosts: () => dispatch(getBlogPosts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
