import classNames from "classnames";
import React from "react";
import styles from "../../pages/Community/community.module.scss";

const Introduction = ({ title, details, className }) => {
  return (
    <div className={classNames(className, styles.blog__introduction)}>
      <h1>{title}</h1>
      <p>{details}</p>
    </div>
  );
};

export default Introduction;
