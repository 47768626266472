import React from "react";
import styles from "./services.module.scss";
import classNames from "classnames";
import Button from "../../components/Button/Button";
import EnyataGroup from "../../assets/imgs/enyata-group-pic.png";
import DesignImg from "../../assets/imgs/design-img1.png";
import WebDevImg from "../../assets/imgs/web-dev-img.png";
import MobDevImg from "../../assets/imgs/mob-dev-img.png";
import ServicesImg from "../../assets/imgs/services-img.jpg";
import ProductImg from "../../assets/imgs/product-img.png";
import Section from "../../components/Wrappers/Section";
import Wrapper from "../../components/Wrappers/Wrapper";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div className={styles.services}>
      <Section>
        <Wrapper>
          <div className={classNames(styles.about)}>
            <p className={styles.about__subText}>Service Highlight</p>
            <h1>
              We are a team of product people, designers and developers who help
              companies build great products.
            </h1>
            <p className={styles.about__info}>
              We love products. We believe that design is a collaborative
              process, and together we can make outstanding products.
            </p>
            <Link to="/contact-us">
              <Button>Let’s Talk</Button>
            </Link>
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <div className={classNames(styles.enyataTeam)}>
            <div className={styles.enyataTeam__info}>
              <p className={styles.about__subText}>Enyata Team</p>
              <p className={styles.bodyText}>
                Our team consists of the best minds in engineering, product
                design, data sceiences, quality assurance engineers and product
                managers. We pull our collective efforts and ideas together to
                ensure we give value to our clients and deliver only world-class
                products that our clients love and are satisfied with. As a
                team, we are geared towards excellence and it reflects on our
                products.
              </p>
            </div>
            <div className={styles.enyataTeam__img}>
              <img
                src={EnyataGroup}
                alt="Enyata staff posing for a photograph"
              />
            </div>
          </div>
        </Wrapper>
      </Section>
      <Section className={styles.design__section}>
        <Wrapper>
          <div className={styles.design__section__wrapper}>
            <div className={styles.design__section__imgGroup}>
              <img
                src={DesignImg}
                alt="Enyata designers having a brainstorming session"
              />
            </div>
            <div
              className={classNames(
                styles.section__text,
                styles.enyataTeam__info
              )}
            >
              <p className={styles.about__subText}>Product Design Team</p>
              <h3 className={styles.headerText}>
                Design better interfaces, keep your users engaged.
              </h3>
              <p className={styles.bodyText}>
                Our design team is committed to delivering cutting-edge product
                designs. We harness your thoughts, visions and ideas and help
                design them into reality. It starts from the product research,
                to the wifeframes and then a splash of colours to match your
                brand perception. We design with your brand in mind.
              </p>
            </div>
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <div>
            <div className={styles.webDev__section__wrapper}>
              <div className={classNames(styles.enyataTeam__info)}>
                <p className={styles.about__subText}>Web Development Team</p>
                <h3 className={styles.headerText}>
                  Collaborate,build and ship world-class products.
                </h3>
                <p className={styles.bodyText}>
                  Develop, build and ship software products with zero errors.
                  Collaborate with our team of engineers to deliver reliable and
                  efficient software products. Save time, cost, energy and get
                  the best results when you build with Enyata.
                </p>
              </div>
              <div className={styles.service__section__imgGroup}>
                <img
                  src={WebDevImg}
                  alt="Enyata web developers working on a project"
                />
              </div>
            </div>
          </div>
        </Wrapper>
      </Section>
      <div>
        <Section>
          <Wrapper>
            <div className={styles.quote}>
              <h3>
                “We want to build a world where great ideas, products, and
                companies come to fruition quickly whilst achieving the required
                impact. Our work is to facilitate the process.”
              </h3>
              <p>Oladayo Oyelade - CTO, enyata</p>
            </div>
          </Wrapper>
        </Section>
      </div>
      <Section>
        <Wrapper>
          <div>
            <div className={styles.mobDev__section__wrapper}>
              <div className={styles.service__section__imgGroup}>
                <img
                  src={MobDevImg}
                  alt="Enyata mobile engineer working on a laptop and screen"
                />
              </div>
              <div className={classNames(styles.enyataTeam__info)}>
                <p className={styles.about__subText}>Mobile Development Team</p>
                <h3 className={styles.headerText}>
                  Go from ideation to live mobile screens.
                </h3>
                <p className={styles.bodyText}>
                  Our mobile developers are well grounded with the best
                  technological frameworks. Trust us with mobile split screens
                  and high functionality software applications. We build for
                  quality.
                </p>
              </div>
            </div>
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <div>
            <div className={styles.product__section__wrapper}>
              <div className={classNames(styles.enyataTeam__info)}>
                <p className={styles.about__subText}>Product Team</p>
                <h3 className={styles.headerText}>
                  Hands on product managers for swift delivery of projects.
                </h3>
                <p className={styles.bodyText}>
                  Work with our team of product managers to effectively deliver
                  products. Product managers serve as mediators between the
                  clients and the developers. Our product managers ensure the
                  best product quality and speed up project delivery.
                </p>
              </div>
              <div className={styles.service__section__imgGroup}>
                <img
                  src={ProductImg}
                  alt="Enyata product team having a brainstorming session"
                />
              </div>
            </div>
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <p className={styles.about__subText}>Our Services</p>
          <p className={styles.bodyText}>
            Smart organizations are confident with out-sourcing their
            technological needs. Enyata engineering provides tailored-expertise
            to carter for all your software development needs. Employ the
            services of our word-class team for best results at every product
            decelopment stage.
          </p>
          <div className={styles.service__group__imgGroup}>
            <img
              src={ServicesImg}
              alt="Enyata designers looking at a laptop screen placed on a white work table"
            />
          </div>
        </Wrapper>
      </Section>
    </div>
  );
};

export default Services;
