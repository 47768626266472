import { Store } from 'react-notifications-component';

const Alert = (message, type) => {
    return message && Store.addNotification({
        message,
        type,
        insert: "right",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 2000 },
        dismissable: { click: true },
        props: {
            className: "error"
        },
        width: 300,
        height: 500
    });
}


export default Alert;
