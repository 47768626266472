import React, { useEffect } from "react";
import { connect } from "react-redux";
import Typewriter from "typewriter-effect";
import { Link } from "react-router-dom";
import styles from "./home.module.scss";
import Navbar from "../../components/Navbar/Navbar";
import Button from "../../components/Button/Button";
import Landing from "../../assets/imgs/landing-image.jpg";
import Icon from "../../assets/imgs/arrow.svg";
import IconMobile from "../../assets/imgs/arrow-mobile.svg";
import DiverseCard from "../../assets/imgs/successful-card.svg";
import AgileCard from "../../assets/imgs/agile-card.jpg";
import Diverse from "../../assets/imgs/verified.svg";
import Agile from "../../assets/imgs/agile-culture.jpg";
import Forward from "../../assets/imgs/arrow-forward.svg";
import Services from "../../assets/imgs/service.jpg";
import EnyataMap from "../../assets/imgs/map.gif";
import CaseStudies from "../../components/Home/CaseStudies";
import Blog from "../../components/Home/Blog";
import Events from "../../components/Home/Events";
import Testimonies from "../../components/Home/Testimonies";
import Section from "../../components/Wrappers/Section";
import Wrapper from "../../components/Wrappers/Wrapper";
import { getBlogPosts } from "../../redux/Actions/CommunityActions";
// import arrowRight from "../../assets/icons/arrow-right.svg";

const Home = ({ blogposts, getPosts }) => {
  useEffect(() => {
    getPosts();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.home}>
      <Section className={styles.about__section}>
        <div className={styles.about__maxWidth}>
          <Navbar theme="primary" />
          <div className={styles.about}>
            <div className={styles.about__left}>
              {/* <div className={styles.about__left__title}>
                <a
                  href="https://buildup.enyata.com"
                  target="_blank"
                  rel="noreferrer noopener">
                  BuildUp 3.0 is happening!!! 🎉 Register here
                  <img src={arrowRight} alt="arrow right icon" />
                </a>
              </div> */}
              <h1>
                Build and ship
                <div className={styles.typewriter}>
                  <span className={styles.highlight}>
                    <Typewriter
                      options={{
                        strings: [
                          "scalable products",
                          "secure products",
                          "efficient products",
                          "reliable products",
                          "robust products",
                        ],
                        autoStart: true,
                        loop: true,
                        cursor: "",
                        delay: "natural",
                      }}
                    />
                  </span>
                </div>
                with on-demand engineering teams.
              </h1>
              <p>
                Leverage Enyata's world-class engineering teams
                to get your ideas and projects up and running
                quickly.
              </p>
              <Link to="/contact-us">
                <Button>Let’s Talk</Button>
              </Link>
            </div>

            <div className={styles.about__right}>
              <div className={styles.icon__one}>
                <img src={Icon} alt="arrow-icon" />
              </div>
              <div className={styles.landing}>
                <img
                  src={Landing}
                  alt="Enyata landing pic showing Enyata staff"
                />
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section className={styles.products__section}>
        <Wrapper className={styles.products}>
          <div className={styles.icon__two}>
            <img src={IconMobile} alt="arrow-icon" />
          </div>
          <div className={styles.products__top}>
            <div>
              <h2>On-demand engineering team augmentation</h2>
              <p>
                Enyata helps you set the stage for user-centric products by
                implementing the right strategies through a combination of
                market research, agile sprints, and wide-ranging expertise.
              </p>
            </div>
          </div>
          <div className={styles.products__application}>
            <div className={styles.products__left}>
              <h3>Application Development</h3>
              <p className={styles.info}>
                Simplify your business process with fully functional,
                visually-compelling web and mobile applications.
              </p>
              <Link to="/services">
                <Button theme="red">See our Process</Button>
              </Link>
            </div>
          </div>

          <div className={styles.products__others}>
            <div className={styles.design}>
              <h3>Product Design</h3>
              <p className={styles.info}>
                Leverage design talent at scale to build customer-centric
                digital experiences.
              </p>
              <Link to="/services">
                <Button theme="dark">Learn More</Button>
              </Link>
            </div>
            <div className={styles.blockchain}>
              <h3>Blockchain Development</h3>
              <p className={styles.info}>
                Build trust with your users with intricate and secure blockchain
                systems.
              </p>
              <Link to="/services">
                <Button theme="white">Learn More</Button>
              </Link>
            </div>
            <div className={styles.data}>
              <h3>Data Science</h3>
              <p className={styles.info}>
                Develop algorithms and machine learning infrastructure with our
                data scientists & engineers.
              </p>
              <Link to="/services">
                <Button theme="grey">Learn More</Button>
              </Link>
            </div>
          </div>
        </Wrapper>
      </Section>

      <CaseStudies />

      <Section className={styles.culture__section}>
        <Wrapper className={styles.culture}>
          <div className={styles.culture__header} data-aos="slide-up">
            <h2>From Ideas to design to market-ready product.</h2>
            <p>
              Our process is designed to optimise product-market fit by carving
              a multidisciplinary team to collaborate with you and deliver
              highly profitable solutions.
            </p>
          </div>

          <div className={styles.culture__types} style={{ overflow: "hidden" }}>
            <div
              className={styles.culture__types__single}
              data-aos="fade-right"
            >
              <div className={styles.header}>
                <div className={styles.header__icon}>
                  <img src={Diverse} alt="diverse-icon" />
                </div>
                <h3>Dedicated Teams</h3>
              </div>

              <p>
                We default to dedicated teams of product, design, and
                engineering experts, but calibrate the mix to best suit the
                challenge at hand.
              </p>

              <img src={DiverseCard} alt="diverse-card" />
            </div>

            <div className={styles.culture__types__single} data-aos="fade-left">
              <div className={styles.header}>
                <div className={styles.header__icon}>
                  <img src={Agile} alt="agile-icon" />
                </div>
                <h3>Agile Development</h3>
              </div>

              <p>
                No two projects ever start from the same point, so we flex and
                adjust accordingly, optimising processes and interactions for
                the best results.
              </p>

              <img src={AgileCard} alt="agile-card" />
            </div>
          </div>
        </Wrapper>
      </Section>

      <Blog blogposts={blogposts} />

      <Section className={styles.services__section}>
        <Wrapper className={styles.services} style={{ overflow: "hidden" }}>
          <img src={Services} alt="services" />
          <div className={styles.services__content}>
            <h3>Service Highlight</h3>
            <p>
              We deliver incredible mobile experiences with native iOS and
              Android apps. And when it comes to cross-platform solutions, our
              expertise and technology provide a good balance between
              development efficiency and the performance and feel of a native
              application. With our help, your ideas are transported from vision
              to version.
            </p>

            <Link to="/services" className={styles.home__link}>
              <span>Check out our Services</span>
              <img src={Forward} alt="read more" />
            </Link>
          </div>
        </Wrapper>
      </Section>

      <Events />

      <Section className={styles.map__section}>
        <Wrapper className={styles.map}>
          <div className={styles.home__header}>
            <h2>Our presence is felt globally</h2>
            <p>
              Our teams are based in 4 countries, and we help businesses around the world build solutions that create impact.
            </p>
          </div>

          <div className={styles.map__video}>
            <img src={EnyataMap} alt="enyata map" />
          </div>
        </Wrapper>
      </Section>

      <Testimonies />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.community.isLoading,
  success: state.community.success,
  blogposts: state.community.posts,
});

const mapDispatchToProps = (dispatch) => ({
  getPosts: () => dispatch(getBlogPosts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
