import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../careers.module.scss";
import classNames from "classnames";
import { positions } from "./data.js";
import noJobs from "../../../assets/icons/no-jobs.svg";
import arrowUpRight from "../../../assets/icons/arrow-up-right.svg";

const OpenPositions = () => {
  const tabs = [
    "All",
    "Engineering",
    "Product",
    "Marketing",
    "Sales",
    "Administration",
  ];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const navigate = useNavigate();

  const filteredJobs = positions.filter(
    (job) => job.category === activeTab || activeTab === "All"
  );

  const goToSingleJob = (data) => {
    navigate(`/careers/${data?.url}`);
  };

  return (
    <div className={styles.jobs}>
      <h1 className={styles.jobs__headerText}>Open Positions</h1>
      <div className={styles["jobs__positions-tabs"]}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={classNames({
              [styles.active]: activeTab === tab,
            })}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </div>
        ))}
      </div>

      {!filteredJobs.length ? (
        <div className={styles.jobs__noJobs}>
          <img src={noJobs} alt="job search icon" />
          <p>
            Unfortunately, there are no jobs available. If you would like to stay updated,{" "}
            <a
              href="https://join.slack.com/t/enyatacommunity/shared_invite/zt-1g9q91jt0-2GmdsnNJ9__yN5PEQ2msCw"
              target="_blank"
              rel="noopener noreferrer"
            >click here</a>{" "}
          </p>
        </div>
      ) : (
        <div className={styles.jobCard__wrapper}>
          {filteredJobs.map((role, index) => (
            <div className={styles.jobCard} key={index}>
              <div className={styles.jobCard__header}>
                <h4>{role?.roleName}</h4>
                <div
                  className={styles.jobCard__view}
                  onClick={() => goToSingleJob(role)}
                >
                  <span>View Job</span>
                  <img src={arrowUpRight} alt="arrow up right icon" />
                </div>
              </div>

              <p>{role?.roleType}</p>
              <div className={styles.jobCard__tags}>
                <div className={styles.countryTag}>
                  <img src={role?.countryFlag} alt={role?.altText} />
                  <p className={styles.countryTag__name}>{role?.country}</p>
                </div>
                <div className={styles.teamTag}>
                  <p
                    className={classNames(
                      styles.countryTag__name,
                      styles.teamTag__name
                    )}
                  >
                    {role?.category}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OpenPositions;
