import Actions from "../Types";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CONTACT_US_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case Actions.CONTACT_US_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
      };
    case Actions.CONTACT_US_FAILURE:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      };
    case Actions.CONTACT_US_INITIAL:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: null,
      };
    case Actions.ACADEMY_CONTACT_US_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case Actions.ACADEMY_CONTACT_US_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
      };
    case Actions.ACADEMY_CONTACT_US_FAILURE:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default reduxState;
