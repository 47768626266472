import styles from "./location.module.scss";
import CanadaFlag from "../../assets/imgs/CanadaFlag.svg";
import UKFlag from "../../assets/imgs/UnitedKingdomFlag.svg";
import NigeriaFlag from "../../assets/imgs/NigeriaFlag.svg";
import GhanaFLag from "../../assets/imgs/GhanaFlag.png";
import USAFlag from "../../assets/imgs/USAFlag.svg"
import Section from "../../components/Wrappers/Section";
import Wrapper from "../../components/Wrappers/Wrapper";

const Location = () => {
  return (
    <Section className={styles.connect}>
      <Wrapper className={styles.connect__cards}>
        <div className={styles.connect__cards__card__canada}>
          <img src={CanadaFlag} alt="Canada Flag" />
          <h6>Canada <span>(HQ)</span></h6>
          <p>
            1 University Ave, <br /> Toronto <br /> ON M5J 2P1
            <br /> Canada.
          </p>
          <p>
            <a
              href="mailto:partnerships@enyata.com"
              rel="noreferrer noopener"
              target="_blank"
            >
              partnerships@enyata.com
            </a>{" "}
            <br /> <a href="tel:+15142624714">+1 514 262 4714</a>
          </p>
        </div>
        <div className={styles.connect__cards__card__usa}>
          <img src={USAFlag} alt="Usa Flag" />
          <h6>United States of America</h6>
          <p>
            38 Wampus Lake Drive, <br /> Armonk, <br /> NY 10504
            <br /> USA.
          </p>
          <p>
            <a
              href="mailto:partnerships@enyata.com"
              rel="noreferrer noopener"
              target="_blank"
            >
              partnerships@enyata.com
            </a>{" "}
          </p>
        </div>
        <div className={styles.connect__cards__card__uk}>
          <img src={UKFlag} alt="UK Flag" />
          <h6>United Kingdom</h6>
          <p>
            3Plot 1 Kiln Gate <br /> 19 Lingard Street Stoke <br /> Trent <br />{" "}
            ST6 1ED
          </p>
          <p>
            <a
              href="mailto:partnerships@enyata.com"
              rel="noreferrer noopener"
              target="_blank"
            >
              partnerships@enyata.com
            </a>{" "}
            <br /> <a href="tel:+447865216907">+447865216907</a>
          </p>
        </div>
        <div className={styles.connect__cards__card__lagos}>
          <img src={NigeriaFlag} alt="Nigeria Flag" />
          <h6>Nigeria</h6>
          <p>
            <a
              href="https://www.google.com/maps/place/Enyata+Inc./@6.5409698,3.356002,15z/data=!4m6!3m5!1s0x103b8d6adf7d39e9:0xea85bba66ae64a23!8m2!3d6.5409698!4d3.356002!16s%2Fg%2F11j2s80_mz"
              rel="noreferrer noopener"
              target="_blank"
            >
              371 Agege Motor Road, <br /> Challenge Plaza, Challenge Bus stop,{" "}
              <br /> Papa Ajao, Lagos, <br /> Nigeria.
            </a>
          </p>
          <p>
            <a
              href="mailto:partnerships@enyata.com"
              rel="noreferrer noopener"
              target="_blank"
            >
              partnerships@enyata.com
            </a>{" "}
            <br /> <a href="tel:+2348107595941">+234 810 7595 941</a>
          </p>
        </div>
        <div className={styles.connect__cards__card__ghana}>
          <img src={GhanaFLag} alt="Ghana Flag" />
          <h6>Ghana</h6>
          <p>
            <a
              href="https://www.google.com/maps/place/TCL+Ghana+Limited/@5.6335664,-0.1672107,3932m/data=!3m1!1e3!4m14!1m7!3m6!1s0xfdf9b7c2e572b7d:0xe5832fbe80480a72!2sTCL+Ghana+Limited!8m2!3d5.6335664!4d-0.1672107!16s%2Fg%2F11rzq0b84y!3m5!1s0xfdf9b7c2e572b7d:0xe5832fbe80480a72!8m2!3d5.6335664!4d-0.1672107!16s%2Fg%2F11rzq0b84y"
              rel="noreferrer noopener"
              target="_blank"
            >
              Attah Pokua Building Top Floor, <br /> East Legon Boundary Road, <br />
              East Legon Accra, <br /> Ghana.
            </a>
          </p>
          <p>
            <a
              href="mailto:partnerships@enyata.com"
              rel="noreferrer noopener"
              target="_blank"
            >
              partnerships@enyata.com
            </a>{" "}
            <br /> <a href="tel:+233506779027">+233 506 779 027</a>
          </p>
        </div>
      </Wrapper>
    </Section>
  );
};

export default Location;
