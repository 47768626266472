import React, { useEffect } from "react";
import { useForm } from 'react-hook-form'
import { connect } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import styles from "./ContactUs.module.scss";
import classNames from "classnames";
import JamesSchuler from '../../assets/imgs/james.png';

import Location from '../../components/Location/Location';
import Section from "../../components/Wrappers/Section";
import Wrapper from "../../components/Wrappers/Wrapper";

import { contactUs } from "../../redux/Actions/AuthActions";


const ContactUsPage = ({ contactUs, success }) => {

  const schema = yup.object().shape({
    name: yup.string().required('First Name is required.'),
    email: yup.string().email().required('Email is required.'),
    phone_number: yup.string().required('Phone Number is required.'),
    about: yup.string().required('Tell us what you are looking to build with Enyata.'),
  });

  const { handleSubmit, register, formState: { errors }, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    reset({
      name: '',
      email: '',
      about: '',
      last_name: '',
      phone_number: '',
      industry: '',
      country: ''
    })
  },
    // eslint-disable-next-line
    [success])


  return (
    <Section>
      <Wrapper>
        <div>
          <div className={classNames(styles.hero)}>
            <div className={styles.hero__left}>
              <h1>Contact Sales.</h1>
              <p>Looking for the solution that best fits your business needs?
                Fill out the contact form and our Sales team will be in touch.
              </p>
              <div className={styles.hero__left__testimony}>
                <div className={styles.hero__left__testimony__text}>
                  <p>“Working with Enyata, has helped us deliver creatively
                    on multiple projects through the years. Their work has always
                    been fantastic, and we hope to work with them in the future.
                  </p>
                  <p>Their Design thought process was customer focused and was
                    very simple.” Great Stuff!
                  </p>
                </div>
                <div className={styles.hero__left__testimony__name}>
                  <img src={JamesSchuler} alt='James Schuler' />
                  <div>
                    <p>James Schuler</p>
                    <p>CTO Kafene</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.hero__right}>
              <div className={styles.hero__right__form}>
                <form onSubmit={handleSubmit(contactUs)}>
                  <div className={styles.input__form__grid}>
                    <div className={styles.input__form__grid__child}>
                      <label htmlFor="first name">First Name</label>
                      <input
                        type="text"
                        className={errors.name?.message ? styles.form_error_border : ''}
                        {...register('name')} />
                      <p className={styles.form_error}>{errors.name?.message}</p>
                    </div>
                    <div className={styles.input__form__grid__child}>
                      <label htmlFor="last name">Last Name</label>
                      <input
                        type="text"
                        {...register('last_name')} />
                    </div>
                  </div>
                  <div className={styles.input__form__full}>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className={errors.email?.message ? styles.form_error_border : ''}
                      {...register('email')} />
                    <p className={styles.form_error}>{errors.email?.message}</p>
                  </div>
                  <div className={styles.input__form__full}>
                    <label htmlFor="phone number">Phone Number</label>
                    <input
                      type="text"
                      className={errors.phone_number?.message ? styles.form_error_border : ''}
                      {...register('phone_number')} />
                    <p className={styles.form_error}>{errors.phone_number?.message}</p>
                  </div>
                  
                  <div className={styles.input__form__full}>
                    <label htmlFor="about">What are you looking to build with Enyata?</label>
                    <textarea
                      name="about"
                      id="reason-of-patnership"
                      placeholder='Tell us more'
                      className={errors.about?.message ? styles.form_error_border : ''}
                      {...register('about')}></textarea>
                    <p className={styles.form_error}>{errors.about?.message}</p>
                  </div>
                  <button type="submit">Send Message</button>
                </form>
              </div>
            </div>
          </div>
          <div className={styles.connect__heading}>
            <h5>Begin with a quick 30 minute call with our sales team or pay us a visit</h5>
          </div>
          <Location />
        </div>
      </Wrapper>
    </Section>
  )
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  success: state.auth.success,
});

const mapDispatchToProps = (dispatch) => ({
  contactUs: (payload) => {
    dispatch(contactUs(payload))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);
