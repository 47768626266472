import classNames from "classnames";
import React from "react";
import styles from "./button.module.scss";

const Button = ({
  children,
  width,
  height,
  disabled = false,
  variant = "solid",
  type = "button",
  align,
  wide,
  onClick,
  loading,
  theme = "primary",
  ...props
}) => {
  const { className, ...args } = props;

  return (
    <button
      onClick={onClick}
      type={type}
      style={{ width: `${width}px`, height: `${height}px` }}
      className={classNames(
        styles.btn,
        styles[`btn__${theme}`],
        styles[`${variant}`],
        wide && styles.wide,
        align && styles[`${align}`],
        className
      )}
      disabled={disabled}
      {...args}
    >
      {loading ? "" : children}
    </button>
  );
};

export default Button;
