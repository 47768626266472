import Section from "../../components/Wrappers/Section";
import Wrapper from "../../components/Wrappers/Wrapper";
import Button from "../../components/Button/Button";
import styles from "./careers.module.scss";
import CareerImg1 from "../../assets/imgs/career-img-1.png";
import CareerImg2 from "../../assets/imgs/career-img-2.png";
import CareerImg3 from "../../assets/imgs/career-img-3.png";
import CareerImg4 from "../../assets/imgs/career-img-4.png";
import CareerImg5 from "../../assets/imgs/career-img-5.png";
import assessmentIcon from "../../assets/icons/assessment.svg";
import screeningIcon from "../../assets/icons/screening.svg";
import interviewIcon from "../../assets/icons/interview.svg";
import offerIcon from "../../assets/icons/offer.svg";
import OpenPositions from "./OpenPositions";
import classNames from "classnames";

const Careers = () => {
  const hiringProcesses = [
    {
      icon: screeningIcon,
      processName: "Screening",
      processInfo:
        "The screening process helps us understand your personality and also helps us see if your goals aligns with us as a team. It is important you get through this phase.",
      altText: "Screening Icon",
    },
    {
      icon: interviewIcon,
      processName: "Interview",
      processInfo:
        "You are then scheduled for an interview with the hiring team. Our interview process is usually fun, exciting and an oppourtunity to learn about you.",
      altText: "Interview Icon",
    },
    {
      icon: assessmentIcon,
      processName: "Assessment",
      processInfo:
        "Successful applicants who make it through the screening and Interview process, are given a simple technical task relating to their skillset.",
      altText: "Assessment Icon",
    },
    {
      icon: offerIcon,
      processName: "Offer",
      processInfo:
        "Once you have satisfied all requirement for employment, our hiring team would reach out to you with an offer letter along side a work contract.",
      altText: "Offer Icon",
    },
  ];
  return (
    <div>
      <Section>
        <Wrapper>
          <div className={styles.careers}>
            <p className={styles.subText}>Our Careers</p>
            <h1 className={styles.careers__headerText}>
              Enyata is home to some of the most value-driven minds.
            </h1>
            <p className={styles.bodyText}>
              Our team is dedicated to exceptional work — without exception. <br />
              We are curious, passionate, open-minded, and dedicated to creating value for everyone involved, including you.
            </p>
            <a href="#openings">
              <Button>Check our openings</Button>
            </a>
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <div className={styles.imageSection}>
            <div>
              <div className={styles.imageSection__imgCard__sm}>
                <img
                  src={CareerImg1}
                  alt="Enyata staff posing for a photograph"
                />
              </div>
              <div className={styles.imageSection__imgCard__sm}>
                <img
                  src={CareerImg2}
                  alt="Enyata staff posing for a photograph"
                />
              </div>
              <div className={styles.imageSection__imgCard__sm}>
                <img
                  src={CareerImg3}
                  alt="Enyata staff posing for a photograph"
                />
              </div>
              <div className={styles.imageSection__imgCard__sm}>
                <img
                  src={CareerImg4}
                  alt="Enyata staff posing for a photograph"
                />
              </div>
            </div>
            <div className={styles.imageSection__imgCard__lg}>
              <img
                src={CareerImg5}
                alt="Enyata staff posing for a photograph"
              />
            </div>
          </div>
        </Wrapper>
      </Section>
      <Section className={styles.companyInfo}>
        <Wrapper>
          <div className={styles.companyInfo__wrapper}>
            <div className={styles.about__info}>
              <h4>Who We Are</h4>
              <p className={styles.about__info__text}>
                We are a technology company providing engineering-as-a-service
                solutions at scale to organizations across multiple industries.
                Our expert teams of engineers, data scientists, designers, and
                product managers aggregate decades of industry experience.
                Innovation and sustainability are at the core of the work we do
                at Enyata, and that is why our clients trust us.
              </p>
            </div>
            <div className={styles.about__quote}>
              <h3>
                “We want to build a world where great ideas, products, and
                companies come to fruition quickly whilst achieving the required
                impact. Our work is to facilitate the process.”
              </h3>
              <p
                className={classNames(
                  styles.about__info__text,
                  styles.about__quote__text
                )}
              >
                Oladayo Oyelade - CTO, enyata
              </p>
            </div>
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <div className={styles.hiringSection}>
            <p className={styles.subText}>Enyata Hiring Process</p>
            <h2 className={styles.hiringSection__headerText}>
              Our hiring process in a nutshell
            </h2>
            <p className={styles.bodyText}>
              Our work at Enyata goes beyond a regular job. We measure success
              and Impact in communities we work with.
            </p>
          </div>
          <div className={styles.hiringCardWrapper}>
            {hiringProcesses.map(
              ({ icon, processName, processInfo, altText }, index) => (
                <div key={index} className={styles.hiringCard}>
                  <div className={styles.hiringCard__icon}>
                    <img src={icon} alt={altText} />
                  </div>
                  <h4>{processName}</h4>
                  <p>{processInfo}</p>
                </div>
              )
            )}
          </div>
        </Wrapper>
      </Section>
      <div id="openings">
        <Section>
          <Wrapper>
            <OpenPositions />
          </Wrapper>
        </Section>
      </div>
    </div>
  );
};

export default Careers;
