import Axios from "axios";
import Actions from "../Types";

// GET BLOG POSTS
export const getBlogPostsStart = () => ({
  type: Actions.GET_BLOG_POSTS_START,
});

export const getBlogPostsSuccess = (payload) => ({
  type: Actions.GET_BLOG_POSTS_SUCCESS,
  payload,
});

export const getBlogPostsFailure = (payload) => ({
  type: Actions.GET_BLOG_POSTS_FAILURE,
  payload,
});

export const getBlogPosts = (payload) => async (dispatch) => {
  dispatch(getBlogPostsStart());
  Axios.get(
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@enyata",
    payload
  )
    .then((data) => {
      dispatch(getBlogPostsSuccess(data && data.data));
    })
    .catch((e) => {
      dispatch(getBlogPostsFailure(e));
    });
};
