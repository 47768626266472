import React from "react";
import { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import classNames from "classnames";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import AllRoutes from "./routes";
import styles from "./App.module.scss";
import { useLocation } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Loader from "./components/Loader/Loader"
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-212587590-1";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  AOS.init({
    duration: 1200,
  });
  const { pathname } = useLocation();


  const theme =
    pathname.includes("/community") || pathname.includes("/privacy-policy")
      ? "secondary"
      : "primary";

  const [isReady, setReady] = useState(false);

  useEffect(() => {

    if (pathname === '/') {
      const timer = setTimeout(() => {
        setReady("ready");
      }, 3500);

      return () => clearTimeout(timer);
    }
    const timer = setTimeout(() => {
      setReady("ready");
    }, 0);

    return () => clearTimeout(timer);
  },
    // eslint-disable-next-line 
    []);

  return (
    <>
      {isReady && (
        <div
          className=
          {classNames
            (styles.container,
              isReady
              && pathname === "/"
              && styles.fadein
            )}>
          <ReactNotifications />
          {pathname !== "/" && <Navbar theme={theme} />}
          <div className={styles.container__body}>
            <AllRoutes />
          </div>
          <Footer />
          <ScrollToTop />
        </div>
      )}
      {!isReady && pathname === "/" && <Loader />}
    </>
  );
}

export default App;
