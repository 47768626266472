import NigeriaFlag from "../../../assets/icons/nigeria-flag.svg";
import GhanaFlag from "../../../assets/icons/ghana-flag.svg";
// import CanadaFlag from "../../../assets/icons/canada-flag.svg";
// import UkFlag from "../../../assets/icons/uk-flag.svg";

export const positions = [
  {
    roleName: "Mid-Level Backend Engineer",
    url: "backend-engineer",
    category: "Engineering",
    country: "Ghana",
    countryFlag: GhanaFlag,
    roleType: "Fulltime, Onsite",
    altText: "Icon of the Ghana flag",
    location: "Accra, Ghana | Full-time",
    introduction:
      "The Ghana Enyata team is looking to hire a Backend Engineer proficient in Nodejs to contribute to the technical needs of the department.",
    principal_accountabilities: [
      "Operate effectively as a member of the Engineering team.",
      "Operate effectively as an individual for quick turnaround of enhancements and adjustments.",
      "Demonstrates technical and analytical skills.",
      "Demonstrates ability to communicate effectively in both technical and business environments.",
    ],
    job_requirements_intro:
      "You will report to the Head of Engineering. Your responsibilities will include:",
    job_requirements: [
      "3+ yrs experience in professional software development.",
      "Great problem-solving skills.",
      "Great understanding of software architecture.",
      "Great understanding of software principles and their application.",
      "Great understanding of software design patterns.",
      "Great understanding of data structure/algorithms.",
      "Great understanding of SQL and No-SQL databases.",
      "Hands-on experience building high-performance applications.",
      "Extensive experience as a Full-stack developer (Backend, Frontend, Mobile)",
      "Versatility using software development tools/frameworks.",
    ],
    qualifications: [
      "Excellent communications, listening, and writing skills",
      "Multitasking abilities",
      "Experience working with other frameworks.",
      "Ability to meet tight deadlines.",
      "Excellent organizational and planning",
      "The ability to minimize ego, take ownership, take criticism, take direction, and occasionally eat crow with a beamingly positive attitude.",
    ],
  },
  {
    roleName: "Mid-Level Frontend Engineer",
    url: "frontend-engineer",
    category: "Engineering",
    country: "Ghana",
    countryFlag: GhanaFlag,
    roleType: "Fulltime, Onsite",
    altText: "Icon of the Ghana flag",
    location: "Accra, Ghana | Full-time",
    introduction:
      "The Ghana Enyata team is looking to hire a Frontend Engineer proficient in React to contribute to the technical needs of the department.",
    principal_accountabilities: [
      "Operate effectively as a member of the Engineering team.",
      "Operate effectively as an individual for quick turnaround of enhancements and adjustments.",
      "Demonstrates technical and analytical skills.",
      "Demonstrates ability to communicate effectively in both technical and business environments.",
    ],
    job_requirements_intro:
      "You will report to the Head of Engineering. Your responsibilities will include:",
    job_requirements: [
      "3+ yrs experience in a related field.",
      "Capable of prioritizing multiple projects in order to meet goals without management oversight.",
      "Vast knowledge of HTML, CSS, SASS/SCSS and JavaScript.",
      "Good understanding of one or more JS Frameworks (eg Vue. js, React (preferred), etc).",
      "Ability to implement near pixel-perfect web apps from designs.",
      "Implementing unit tests and end-to-end tests.",
      "Proficient understanding of code versioning tools, such as Git.",
      "Understanding of state management in the framework of choice.",
      "Understanding of web accessibility.",
      "Understands Software development and maintenance lifecycle.",
      "Good understanding of REST APIs and integrating them.",
      "Interest in GraphQL, and web animations."
    ],
    qualifications: [
      "Excellent communications, listening, and writing skills",
      "Multitasking abilities",
      "Experience working with frontend frameworks.",
      "Ability to meet tight deadlines.",
      "Excellent organizational and planning",
      "The ability to minimize ego, take ownership, take criticism, take direction, and occasionally eat crow with a beamingly positive attitude.",
    ],
  },
  {
    roleName: "Product Manager",
    url: "product-manager",
    category: "Product",
    country: "Ghana",
    countryFlag: GhanaFlag,
    roleType: "Fulltime, Onsite",
    altText: "Icon of the Ghana flag",
    location: "Accra, Ghana | Full-time",
    introduction:
      "Enyata Ghana is looking to hire a Product Manager to join our product team.",
    principal_accountabilities: [
      "Develop and implement product strategies consistent with the company's vision.",
      "Collect and analyze feedback from customers, stakeholders, and other teams to shape requirements, features, and end products.",
      "Work with senior management to create product plans and roadmaps.",
      "Lead product managers and coordinate cross-functional teams.",
      "Produce and review product requirements documents (PRD).",
      "Ensure products and releases are launched correctly and on schedule.",
      "Make creative recommendations to expand the product base and vision.",
      "Suggest ways to track product use and impact on end users."
    ],
    job_requirements: [
      "4+ years experience as a Product Manager",
      "Experience in product lifecycle management",
      "Background in software development and program management is preferred",
      "Familiarity with Agile framework",
      "Organizational and leadership abilities",

    ],
    qualifications: [
      "Excellent communications, listening, and writing skills",
      "Multitasking abilities",
      "Ability to meet tight deadlines.",
      "Excellent organizational and planning",
      "The ability to minimize ego, take ownership, take criticism, take direction, and occasionally eat crow with a beamingly positive attitude.",
    ],
  },
  {
    roleName: "DevOps Engineer",
    url: "devops-engineer",
    category: "Engineering",
    country: "Nigeria",
    countryFlag: NigeriaFlag,
    roleType: "Fulltime, Onsite",
    altText: "Icon of the Nigerian flag",
    location: "Lagos, Nigeria | Full-time",
    introduction:
      "We are looking for an experienced DevOps Engineer to join our team. The ideal candidate will have extensive experience with programming languages and a strong understanding of DevOps principles and practices.",
    principal_accountabilities: [
      "Implementation of deployment strategies.",
      "Automate, streamline, and maintain deployment processes (CI/CD).",
      "Monitor, secure, and maintain production systems.",
      "Troubleshoot and debug issues as they arise.",
      "Research and recommend new technologies to improve system performance and scalability.",
      "Collaborate with other teams to identify and resolve complex technical issues."
    ],
    job_requirements_intro:
      "You will report to the Head of DevOps. The ideal canditate should have:",
    job_requirements: [
      "3+ years of experience as a DevOps Engineer or similar role.",
      "Strong experience with at least one programming language (Python, Javascript, etc.).",
      "Experience with containerization and orchestration technologies (Docker, Docker-compose, Kubernetes).",
      "Experience with at least one cloud platform or more (AWS, Azure, GCP).",
      "Familiarity with at least one CI/CD pipeline and tools (Jenkins, GitLab, GitHub actions, etc.)",
      "Strong understanding of system administration and networking concepts.",
      "Experience with at least one monitoring and logging tool (Prometheus, Elastic Stack, Prometheus, Grafana, InfluxDb, Redash, etc.).",
      "Experience with security best practices and tools is a plus."
    ],
    qualifications: [
      "Strong communication and teamwork skills.",
      "Ability to work in a fast-paced and dynamic environment.",
      "Experience with any additional DevOps tool is a plus."
    ],
  },
  {
    roleName: "Digital Marketer",
    url: "digital-marketer",
    category: "Marketing",
    country: "Nigeria",
    countryFlag: NigeriaFlag,
    roleType: "Fulltime, Onsite",
    altText: "Icon of the Nigerian flag",
    location: "Lagos, Nigeria | Full-time",
    introduction:
      `The Marketing & Communications team is looking for an innovative, hardworking digital marketer to join our team. In this role, you’ll work closely with Enyata’s team of experts to create targeted marketing campaigns that reach the right audience, in the right place, at the right time.

      You’ll be the expert at analyzing reports and making campaign recommendations with Meta Ads Manager and Google Ads; you’ll optimize targeting and ad placements across programmatic advertising platforms.
      `,
    principal_accountabilities: [
      "Develop digital marketing strategies by identifying customer cohorts to whom Enyata can be marketed.",
      "Creating & executing campaigns, and analyzing the effectiveness of such campaigns with a focus on international markets.",
      "Manage and Optimize Enyata marketing channels for lead generation.",
      "Plan and execute all web, SEO/SEM, database marketing, and display advertising campaigns.",
      "Contribute to marketing effectiveness by identifying short-term and long-range issues that must be addressed.",
      "Manage paid ads and make performance reports. Perform daily account management and pay-per-click accounts on Google AdWords and other search platforms.",
      "Create paid ads on LinkedIn and other relevant social media platforms.",
      "Assist in the maintenance and monitoring of keyword bids, account daily and monthly budget caps, impression share, quality score, and other important account metrics.",
      "Manage and maintain updates for large keyword lists.",
      "Measure and report the performance of all digital marketing campaigns and assesses against goals (ROI and KPIs).",
      "Develop a strategy that combines technical and content elements, to drive ongoing improvements in SEO and organic search results.",
      "Measure, track, and optimize engagement from the top of the funnel throughout the customer journey to ensure marketing is accelerating sales efforts to close business."
    ],
    job_requirements_intro:
      "You will report to the Head of Marketing and Communication. Your responsibilities will include:",
    job_requirements: [
      "Excellent understanding of digital marketing concepts.",
      "3+ years of hands-on experience in managing digital marketing campaigns.",
      "Proven work experience as a Digital marketing specialist ( SEO, SMO, PPC, Google Ads, Email, ORM Content Marketing).",
      "Must have prior B2B marketing experience",
      "Familiarity with social media trends.",
      "Excellent communication skills."
    ],
    qualifications: [
      "Creative.",
      "Self-drive.n",
      "Ability to communicate clearly and to effectively influence others.",
      "Experience or willingness to learn."
    ],
  },
  {
    roleName: "Research & Development Manager",
    url: "research-development-manager",
    category: "Product",
    country: "Nigeria",
    countryFlag: NigeriaFlag,
    roleType: "Fulltime, Onsite",
    altText: "Icon of the Nigerian flag",
    location: "Lagos, Nigeria | Full-time",
    introduction:
      `Understanding the needs, and pains, of our customers and prospects is the key to building successful products. To understand how users think, we need a product strategy that accounts for ongoing insights through customer interviews, user testing, quantitative data, etc…
      Within the growth of the company and customer base, we need to get deep insights, test new solutions thoughtfully, and understand what customers' pains are before implementation begins.
      The Product Research and Development Manager will be responsible for driving and implementing a research strategy for Enyata’s core product in partnership with the product and design teams. This person will do the research, build, manage, and coach the research team, and drive cross-functional collaboration with product, design, and marketing.
      The research function is expected to play a large and important role in identifying the next product opportunities and driving product growth.      
      `,
    principal_accountabilities: [
      "Build a research strategy in collaboration with the Product and Design teams and execute it.",
      "Hire and supervise the Research team.",
      "Build cross-functional research processes and provide methodology support to Product and Design teams.",
      "Evaluate research tools and implement them into the research process.",
      "Maintain an overall company knowledge about customer insights and provide transparency.",
      "Drive the process of turning insights into action recognizing that observations are only as valuable as their influence on the products they serve.",
      "Collaborate with Sales, Success, and Marketing teams to exchange insights about customers and market needs.",
      "Build frameworks and templates for the Product team to support research projects."
    ],
    job_requirements_intro:
      "You will report to the Head of Product. Your responsibilities will include:",
    job_requirements: [
      "3+ years of proven success leading user research projects with demonstrated impact.",
      "End-to-end experience with all aspects of research.",
      "A strong portfolio demonstrating past work experience and deliverables.",
      "Hands-on experience with lab-based user testing, remote testing, iterative prototype testing, concept testing, ethnographic research, longitudinal research (e.g. diary studies), benchmarking, survey design, and usage of multiple methods within a study.",
      "Data-collection and analysis skills (e.g., designing, conducting, and analyzing all kinds of user data).",
      "Ability to meet ambitious deadlines and deliver high-quality work on schedule, including quickly turning around study plans and reports.",
      "Excellent communication, presentation, interpersonal and analytical skills; the ability to communicate complex concepts clearly across different audiences and levels of the organization.",
      "Demonstrated passion for solving problems.",
      "Experience in working with a distributed team.",
      "English – Advanced or higher.",
      "B2B SaaS experience (especially collaboration space) is a plus."
    ]
  },
  {
    roleName: "Mid-level Backend Engineer",
    url: "mid-level-backend-engineer",
    category: "Engineering",
    country: "Nigeria",
    countryFlag: NigeriaFlag,
    roleType: "Fulltime, Onsite",
    altText: "Icon of the Nigerian flag",
    location: "Lagos, Nigeria | Full-time",
    introduction:
      "The Nigerian Enyata team is looking to hire a Backend Engineer proficient in Nodejs to contribute to the technical needs of the department.",
    principal_accountabilities: [
      "Operate effectively as a member of the Engineering team.",
      "Operate effectively as an individual for quick turnaround of enhancements and adjustments.",
      "Demonstrates technical and analytical skills.",
      "Demonstrates ability to communicate effectively in both technical and business environments.",
    ],
    job_requirements_intro:
      "You will report to the Head of Engineering. Your responsibilities will include:",
    job_requirements: [
      "3+ yrs experience in professional software development.",
      "Great problem-solving skills.",
      "Great understanding of software architecture.",
      "Great understanding of software principles and their application.",
      "Great understanding of software design patterns.",
      "Great understanding of data structure/algorithms.",
      "Great understanding of SQL and No-SQL databases.",
      "Hands-on experience building high-performance applications.",
      "Extensive experience as a Full-stack developer (Backend, Frontend, Mobile)",
      "Versatility using software development tools/frameworks.",
    ],
    qualifications: [
      "Excellent communications, listening, and writing skills",
      "Multitasking abilities",
      "Experience working with other frameworks.",
      "Ability to meet tight deadlines.",
      "Excellent organizational and planning",
      "The ability to minimize ego, take ownership, take criticism, take direction, and occasionally eat crow with a beamingly positive attitude.",
    ],
  },
  {
    roleName: "Mid-level Frontend Engineer (ReactJs)",
    url: "mid-level-frontend-engineer",
    category: "Engineering",
    country: "Nigeria",
    countryFlag: NigeriaFlag,
    roleType: "Fulltime, Onsite",
    altText: "Icon of the Nigerian flag",
    location: "Lagos, Nigeria | Full-time",
    introduction:
      "The Nigerian Enyata team is looking to hire a Frontend Engineer proficient in React to contribute to the technical needs of the department.",
    principal_accountabilities: [
      "Operate effectively as a member of the Engineering team.",
      "Operate effectively as an individual for quick turnaround of enhancements and adjustments.",
      "Demonstrates technical and analytical skills.",
      "Demonstrates ability to communicate effectively in both technical and business environments.",
    ],
    job_requirements_intro:
      "You will report to the Head of Engineering. Your responsibilities will include:",
    job_requirements: [
      "3+ yrs experience in a related field.",
      "Capable of prioritizing multiple projects in order to meet goals without management oversight.",
      "Vast knowledge of HTML, CSS, SASS/SCSS and JavaScript.",
      "Good understanding of one or more JS Frameworks (eg Vue. js, React (preferred), etc).",
      "Ability to implement near pixel-perfect web apps from designs.",
      "Implementing unit tests and end-to-end tests.",
      "Proficient understanding of code versioning tools, such as Git.",
      "Understanding of state management in the framework of choice.",
      "Understanding of web accessibility.",
      "Understands Software development and maintenance lifecycle.",
      "Good understanding of REST APIs and integrating them.",
      "Interest in GraphQL, and web animations."
    ],
    qualifications: [
      "Excellent communications, listening, and writing skills",
      "Multitasking abilities",
      "Experience working with other frameworks.",
      "Ability to meet tight deadlines.",
      "Excellent organizational and planning",
      "The ability to minimize ego, take ownership, take criticism, take direction, and occasionally eat crow with a beamingly positive attitude.",
    ],
  }
];
