import Actions from "../Types";
import Instance from "../../utils/services/api";
import Alert from "../../utils/notifications/Alert";

// CONTACT US
export const contactUsInitial = () => ({
  type: Actions.CONTACT_US_INITIAL,
});

export const contactUsStart = () => ({
  type: Actions.CONTACT_US_START,
});

export const contactUsSuccess = () => ({
  type: Actions.CONTACT_US_SUCCESS,
});

export const contactUsFailure = () => ({
  type: Actions.CONTACT_US_FAILURE,
});


// ACADEMY CONTACT US 
export const academyContactUsStart = () => ({
  type: Actions.ACADEMY_CONTACT_US_START,
});

export const academyContactUsSuccess = () => ({
  type: Actions.ACADEMY_CONTACT_US_SUCCESS,
});

export const academyContactUsFailure = () => ({
  type: Actions.ACADEMY_CONTACT_US_FAILURE,
});

export const contactUs = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance();

  dispatch(contactUsStart());
  API.post("https://websiteapi.enyata.com/project-enquiries", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      Alert('We have recieved your message.', 'success');
      dispatch(contactUsSuccess());
      setTimeout(() => {
        dispatch(contactUsInitial());
      }, 1000);
      return Promise.resolve(data);
    } else {
      dispatch(contactUsFailure());
    }
  });
};

export const academyContactUs = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance();

  dispatch(academyContactUsStart());
  API.post("https://websiteapi.enyata.com/academy-applications", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      Alert('We have recieved your application.', 'success');
      dispatch(academyContactUsSuccess());
      setTimeout(() => {
        dispatch(contactUsInitial());
      }, 1000);
      return Promise.resolve(data);
    } else {
      dispatch(academyContactUsFailure());
    }
  });
};
