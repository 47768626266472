import classNames from "classnames";
import styles from "../caseStudy.module.scss";
import FiclubLogo from "../../../assets/imgs/fi-club-logo.svg";
import FiclubImg from "../../../assets/imgs/ficlub-case-img.png";
import Section from "../../../components/Wrappers/Section";
import Wrapper from "../../../components/Wrappers/Wrapper";

const FiclubCaseStudy = () => {
  return (
    <div>
      <Section>
        <Wrapper>
          <div className={styles.caseStudyPage}>
            <div className={styles.customer}>
              <img src={FiclubLogo} alt="Ficlub logo" />
              <p className={styles.customer__info}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Ullamcorper semper viverra morbi libero habitant. Augue nisl
                semper scelerisque facilisi. Nunc est tortor massa cursus morbi
                molestie eu volutpat. Facilisis enim proin sed tempor diam
                pellentesque. Risus scelerisque sit pellentesque ipsum dolor.
                Scelerisque hac urna purus, ut tortor.
                <br />
                Nulla quis sit massa fringilla hac egestas tempor. Viverra
                euismod vitae sit nulla tellus nullam feugiat est mattis. Vitae
                enim id ante vel pulvinar egestas. Morbi ut egestas ut sagittis
                ac nisi. Molestie magna at nulla suspendisse duis faucibus. Id
                sodales magna nisl justo justo ut sit.
              </p>
            </div>
            <div
              className={classNames(
                styles.customer__platform,
                styles.customer__platform__violet
              )}
            >
              <p>Platforms:</p>
              <p className={styles.name}>Android & Dashboard</p>
            </div>
          </div>
        </Wrapper>
      </Section>
      <Section className={styles.customerImg}>
        <Wrapper>
          <div className={styles.customerImg__img}>
            <img src={FiclubImg} alt="Ficlub mobile appilcation screens" />
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <div className={styles.caseStudyPage}>
            <h3 className={styles.customer__sectionHeader}>The Challenge</h3>
            <p className={styles.customer__info}>
              Increasingly, people are turning to dating sites and apps to find
              love. And while the pool may seem larger, and access is at our
              fingertips, using them doesn't necessarily improve our chances of
              finding a mate. In a Relationships Australia Survey, approximately
              60% of people surveyed used dating apps and online sites, and of
              these people, about 25% found a long-term partner. The recent
              Australia Talks National Survey conducted by the ABC reported 35%
              of people found their current partner online. <br />
              These statistics tell us people need to try many times to initiate
              a connection before they make a match, and that many connections
              are unlikely to become long-term (or at the very least, "steady
              dating") relationships. Yet, it's estimated that more than 50
              million people use an app such as Tinder, with US millennials
              averaging approximately 1.5 hours a day, according to market
              research.
              <br />
              In Nigeria, dating apps are populary flagged as places for sex
              workers and unserious minded individuals just looking to catch
              fun, they are only a handful applications in Nigeria that have
              created an online space where people can meet their chosen ones.
            </p>
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <div className={styles.caseStudyPage}>
            <div className={styles.customer__solution}>
              <h3 className={styles.customer__sectionHeader}>The Solution</h3>
              <p className={styles.customer__info}>
                We built the Peeamo website, mobile dating app and
                administrators dashboard for Loganware, the application has a
                lot of inbuilt features that makes it distinctive to Nigerian
                professionals. The ‘Communities’ feature rolled out in the app
                was also designed for the sake of connection but on a larger
                scale, users can create groups and communicate and connect in
                general discussions. <br />
                Another important note is the use of Avatars, Peeamo redefined
                the idea of privacy in dating apps by having the user profiles
                represented by Avatars, and the application has quite a number
                of avatars that covers all possible professions.
              </p>
            </div>
          </div>
        </Wrapper>
      </Section>
    </div>
  );
};

export default FiclubCaseStudy;
