import React from "react";
import { Slide } from "react-slideshow-image";
import styles from "../../pages/Home/home.module.scss";
import Mickey from "../../assets/imgs/Mickey.png";
import James from "../../assets/imgs/james.png"
import Hicolumn from "../../assets/imgs/Elekwachi.png"
import BigAppCompany from "../../assets/imgs/bigAppCompany.png"
import Jide from "../../assets/imgs/Jide.png"
import "react-slideshow-image/dist/styles.css";
import Section from "../Wrappers/Section";
import Wrapper from "../Wrappers/Wrapper";

const Testimonies = () => {
  const testimonies = [
    {
      image_url: James,
      name: "James Schuler",
      position: "CTO Kafene",
      content:
        "“Working with Enyata, has helped us deliver creatively on multiple projects through the years. Their work has always been fantastic, and we hope to work with them in the future. Their Design thought process was customer focused and was very simple.” Great Stuff!",
    },
    {
      image_url: Jide,
      name: "Jide Ogunjobi",
      position: "CTO Moove Africs",
      content:
        "“The accuracy in which they quickly understood what we were trying to build was very impressive. Both the system and app have met our expectations and functions successfully.”",
    },
    {
      image_url: Hicolumn,
      name: "Elekwachi Uche",
      position: "CEO Hicolumn",
      content:
        "“Enyata being an expert in digital transformation and software development were able to articulate and implement our unique solutions with a high degree of competence and standard which we are thankful for.”",
    },
    {
      image_url: Mickey,
      name: "Mickey Costa",
      position: "CEO Spark",
      content:
        "“Enyata always made sure a sprint deadline was never missed. They were able to operate at the competency level of a cofounder, using their skills to add to a vision and ensure the project was properly executed.”",
    },
    {
      image_url: BigAppCompany,
      name: "Saurabh Saxena",
      position: "CTO BigAppCompany",
      content:
        "“I had a pleasure of working with Enyata for my portfolio website development, the result they achieved in those time speaks a volume. They motivate their team to take ownership and responsibility over my project.”",
    },
  ];

  const properties = {
    duration: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    canSwipe: false,
    arrows: false,
    infinite: true,
    easing: "ease",
    indicators: (i) => (
      <div className="indicator">
        <div className="indicator__dot"></div>
      </div>
    ),
  };

  return (
    <Section className={styles.testimonies__section}>
      <Wrapper className={styles.testimonies}>
        <h2>
          We’ve been truly privileged to have worked with{" "}
          <span className={styles.highlight}>great people</span> across multiple
          industries
        </h2>
        <Slide {...properties}>
          {testimonies.map((item, index) => (
            <div className={styles.testimonies__slide} key={index}>
              <div className={styles.author}>
                <div className={styles.image}>
                  <img src={item.image_url} alt={`a pic of ${item.position}`} />
                </div>
                <div className={styles.name}>
                  <h4>{item.name}</h4>
                  <h5>{item.position}</h5>
                </div>
              </div>

              <div className={styles.content}>{item.content}</div>
            </div>
          ))}
        </Slide>
      </Wrapper>
    </Section>
  );
};

export default Testimonies;
