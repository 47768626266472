import React from "react";
import { Link, useParams } from "react-router-dom";
import styles from "./singlejob.module.scss";
import Wrapper from "../../components/Wrappers/Wrapper";
import BulletImage from "../../assets/imgs/bullet-2.svg";
import goBack from "../../assets/icons/go-back.svg";
import Button from "../../components/Button/Button";
import { positions } from "../Careers/OpenPositions/data";

const SingleJob = () => {
  const { role } = useParams();

  const currentRole = positions.filter((job) => job.url === role);
  const data = currentRole && currentRole[0];

  const Paragraph = ({ header, headerSmall, content, children }) => (
    <div className={styles.section}>
      {header && <h2>{header}</h2>}
      {headerSmall && <h3>{headerSmall}</h3>}
      {content && <p>{content}</p>}

      {children}
    </div>
  );

  const Bullet = ({ text }) => (
    <div className={styles.bullet}>
      <div className={styles.bullet__image}>
        <img src={BulletImage} alt="bullet point" />
      </div>
      <span>{text}</span>
    </div>
  );

  const accountabilities = data && data.principal_accountabilities;
  const responsibilities = data && data.job_responsibilities;
  const requirements = data && data.job_requirements;
  const qualifications = data && data.qualifications;

  return (
    <div>
      {data ? (
        <div className={styles.singlejob}>
          <div className={styles.header__section}>
            <Wrapper className={styles.header}>
              <Link className={styles.header__back} to="/careers">
                <img src={goBack} alt="Go back Icon" />
                <span>Back</span>
              </Link>

              <h1 className={styles.header__title}>{data?.roleName}</h1>
              <h5 className={styles.header__type}>{data?.location}</h5>
            </Wrapper>
          </div>

          <div className={styles.content__section}>
            <Wrapper className={styles.content}>
              <Paragraph header="Introduction" content={data?.introduction} />
              <Paragraph
                header="Principal Accountabilities"
                content={data?.job_requirements_intro}>
                {accountabilities?.map((item, index) => (
                  <Bullet text={item} key={index} />
                ))}
              </Paragraph>
              {responsibilities && (
                <Paragraph
                  header="Job Responsibilities:"
                  content={data?.job_responsibilities_intro}
                >
                  {responsibilities?.map((item, index) => (
                    <Bullet text={item} key={index} />
                  ))}
                </Paragraph>
              )}
              {requirements && (
                <Paragraph
                  header="Job Requirements:"
                >
                  {requirements?.map((item, index) => (
                    <Bullet text={item} key={index} />
                  ))}
                </Paragraph>
              )}
              <Paragraph
                headerSmall="The following qualifications will assist the successful candidate in carrying out these responsibilities:"
                content={data?.job_responsibilities_intro}
              >
                {qualifications?.map((item, index) => (
                  <Bullet text={item} key={index} />
                ))}
              </Paragraph>
              <a
                href="http://bit.ly/enyatarecruitment"
                target="_blank"
                rel="noreferrer noopener"
                className={styles.apply__btn}
              >
                <Button height={48}>Apply for this position</Button>
              </a>
            </Wrapper>
          </div>
        </div>
      ) : (
        <div className={styles.singlejob}>
          <div className={styles.empty__section}>
            <Wrapper className={styles.empty}>
              <Link className={styles.empty__back} to="/careers">
                <img src={goBack} alt="Go back Icon" />
                <span>Back</span>
              </Link>

              <div>Error getting this job's description</div>
            </Wrapper>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleJob;
