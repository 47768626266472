import React from "react";
import { Link } from "react-router-dom";
import Wrapper from "../Wrappers/Wrapper";
import styles from "../../pages/Home/home.module.scss";
import Kafene from "../../assets/imgs/casestudy-kafene.jpg";
import CareClick from "../../assets/imgs/casestudy-careclick.jpg";
import Volley from "../../assets/imgs/casestudy-volley.jpg";
import Presmit from "../../assets/imgs/casestudy-presmit.jpg";
import CaseStudyCard from "../../components/CaseStudyCard/CaseStudyCard";
import Section from "../Wrappers/Section";

const CaseStudies = () => {
  const caseStudiesSamples = [
    {
      title: "Presmit",
      info: "A user-centric platform designed for you to trade, buy and sell various digital assets including gift cards, bitcoins, USDT, litecoins, dogecoins, airtime and data topup.",
      image: Presmit,
      url: null,
    },
    {
      title: "Volley",
      info: "Volley help B2B companies talk to their prospects by scaling personalized and hyper-relevant outreach.",
      image: Volley,
      url: null,
    },
    {
      title: "Kafene",
      info: "Kafene Is a digital web and app-based lease to own platform that helps all consumers buy the things they want over time, affordably.",
      image: Kafene,
      url: "/case-studies/kafene",
    },
    {
      title: "Care click",
      info: "Customized lease options add flexibility and affordability to your life. Get the products you want without having to pay for it all upfront.",
      image: CareClick,
      url: "/case-studies/care-click",
    },
  ];

  return (
    <Section className={styles.casestudy__section}>
      <Wrapper>
        <h2>Case Studies</h2>
        <p>
          Some of the projects we have designed and built for our clients.
        </p>

        <CaseStudyCard caseStudies={caseStudiesSamples} />

        <Link className={styles.casestudy__section__all} to="/case-studies">
          <h3>View more case studies</h3>
        </Link>
      </Wrapper>
    </Section>
  );
};

export default CaseStudies;
