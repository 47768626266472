const Actions = {
  CONTACT_US_START: "CONTACT_US_START",
  CONTACT_US_SUCCESS: "CONTACT_US_SUCCESS",
  CONTACT_US_FAILURE: "CONTACT_US_FAILURE",
  CONTACT_US_INITIAL: "CONTACT_US_INITIAL",
  GET_BLOG_POSTS_START: "GET_BLOG_POSTS_START",
  GET_BLOG_POSTS_SUCCESS: "GET_BLOG_POSTS_SUCCESS",
  GET_BLOG_POSTS_FAILURE: "GET_BLOG_POSTS_FAILURE",
  ACADEMY_CONTACT_US_START: "ACADEMY_CONTACT_US_START",
  ACADEMY_CONTACT_US_SUCCESS: "ACADEMY_CONTACT_US_SUCCESS",
  ACADEMY_CONTACT_US_FAILURE: "ACADEMY_CONTACT_US_FAILURE"
};

export default Actions;
