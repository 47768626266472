import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "../../assets/imgs/enyata-logo.svg";
import Button from "../Button/Button";
import "./NotFound.scss";

const NotFound = () => {
    const params = useParams();
    let value = Object.values(params)[0];

    useEffect(() => {
        document.title = "Error 404 (Not Found)";
    }, []);

    return (
        <div className="NotFound flex--4">
            <div>
                <img src={Logo} alt="enyata logo" />
                <div className="error-404">
                    404.
                    <span> That’s an error.</span>
                </div>
                <p>
                    The requested URL <span>/{value}</span> was not found on this server.
                </p>
                <Link to="/">
                    <Button height="40">Let's help you</Button>
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
