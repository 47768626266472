import axios from "axios";
import { create } from "apisauce";

class Instance {
    static customAxiosInstance() {
        const customAxiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_KEY,
            responseType: "json",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        })
        const apisauceInstance = create({
            axiosInstance: customAxiosInstance
        });
        return apisauceInstance;
    }
}


export default Instance;