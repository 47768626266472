import React from "react";
import styles from "../../pages/Home/home.module.scss";
import Forward from "../../assets/imgs/arrow-forward.svg";
import Wrapper from "../Wrappers/Wrapper";
import Section from "../Wrappers/Section";
import { Link } from "react-router-dom";
import {
  calculateReadTime,
  convertToText,
  removeSpecialChar,
} from "../../utils/functions/formatString";

const Blog = ({ blogposts }) => {
  const posts = blogposts && blogposts.items && blogposts.items.slice(0, 3);

  return (
    <Section className={styles.blog__section}>
      <Wrapper className={styles.blog}>
        <div className={styles.home__header}>
          <h2>Our Blog</h2>
          <p>
            Explore our library of expert knowledge - everything from skill
            development to business growth.
          </p>
        </div>

        <div className={styles.blog__posts}>
          {posts &&
            posts.map((blog, index) => (
              <a href={blog.link} target="_blank" rel="noreferrer" key={index}>
                <div className={styles.post}>
                  <div className={styles.image}>
                    <img src={blog.thumbnail} alt="blog-pic" />
                  </div>
                  <div className={styles.details}>
                    <h4>{blog.title}</h4>
                    <h5>{`${convertToText(blog?.description, 120)}...`}</h5>
                    <div className={styles.blog__tags}>
                      <div className={styles.tags}>
                        {blog.categories &&
                          blog.categories[1] &&
                          removeSpecialChar(blog.categories[1])}
                      </div>
                      <span>{calculateReadTime(blog?.content)}</span>
                    </div>
                  </div>
                </div>
              </a>
            ))}
        </div>

        <Link to="/community/blog" className={styles.home__link}>
          <span>Check out our blog</span>
          <img src={Forward} alt="read more" />
        </Link>
      </Wrapper>
    </Section>
  );
};

export default Blog;
