import React, { useState } from "react";
import styles from "./community.module.scss";
import Section from "../../components/Wrappers/Section";
import Wrapper from "../../components/Wrappers/Wrapper";
import Landing from "../../components/Community/Landing";
import Introduction from "../../components/Community/Introduction";
import PodcastCover from "../../assets/imgs/podcast-cover.svg";
import PlayBtn from "../../assets/icons/play-btn.svg";

const Podcast = () => {
  const [activeView, setActiveView] = useState("Podcast");

  return (
    <div className={styles.blog}>
      <Landing
        activeView={activeView}
        setActiveView={setActiveView}
        description="Listen to the raving voices of tech professionals on the Modules by Enyata podcast. Vital insights coming your way with each steaming episode, subscribe so that you dont miss a thing."
      />
      <Section className={styles.media__section}>
        <Wrapper className={styles.media}>
          <Introduction
            className={styles.introduction__podcast}
            title="Modules by Enyata"
            details="Modules by Enyata is an expedition of the tech ecosystem. Each episode explores topics and events that affect and engage every facet of the community."
          />

          <div className={styles.media__anchors}>
            <p>
              <span>Hosted by:</span> Ifedolapo Oseni
            </p>
            <p>
              <span>Sound Director:</span> Korede Ajibola
            </p>
          </div>

          <div className={styles.media__podcast}>
            <img src={PodcastCover} alt="Enyata Podcast Cover" />

            <div className={styles.podcast__details}>
              <h2>Listen on Anchor</h2>
              <p>
                Modules by Enyata is an expedition of the tech ecosystem. <br />
                Modules by Enyata is an Enyata Inc.
              </p>
              <hr />
              <a
                href="https://anchor.fm/enyata"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={PlayBtn} alt="A purple icon of a play button" />
                <span>Listen Now</span>
              </a>
            </div>
          </div>

          <a
            href="https://anchor.fm/enyata"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={PodcastCover}
              alt="Enyata Podcast Cover"
              className={styles.podcast__mobile}
            />
          </a>
        </Wrapper>
      </Section>
    </div>
  );
};

export default Podcast;
