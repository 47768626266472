import { Slide } from "react-slideshow-image";
import styles from "../../pages/Academy/academy.module.scss";
import Cynthia from "../../assets/imgs/cynthia.png";
import Kemi from "../../assets/imgs/kemi.png";
import Gerald from "../../assets/imgs/gerald.png";
import Micheal from "../../assets/imgs/Micheal.png";
import "react-slideshow-image/dist/styles.css";
import Section from "../Wrappers/Section";
import Wrapper from "../Wrappers/Wrapper";

const AcademyTestimonies = () => {
  const testimonies = [
    {
      image_url: Cynthia,
      name: "Cynthia Ilojeme",
      role: "Frontend Engineer",
      testimony: '“I was a microbiology graduate with no prior knowledge of anything Software Development when I started, I now and have been working as a Software Engineer where I develop scalable web applications thanks to the training I got in the academy.”'
    },
    {
      image_url: Micheal,
      name: "Micheal Adisa",
      role: "Frontend Engineer",
      testimony: '“Enyata Academy promised to give a roadmap to building a successful career and teach necessary skills to get started on that path, in four months, and they did. I am now a software engineer, specialising in Frontend Development."'
    },
    {
      image_url: Kemi,
      name: "Kemi Alabede",
      role: "Frontend Engineer",
      testimony: '“Joining the academy was one of the best decisions I ever made as it provided the perfect foundation to learn about software development and set me on the path to become a front end developer.”'
    },
    {
      image_url: Gerald,
      name: "Gerald",
      role: "Mobile Engineer",
      testimony: '"Enyata Academy promised to give a roadmap to building a successful career and teach necessary skills to get started on that path, in four months, and they did. I am now a software engineer, specialising in Mobile Development."'
    }
  ]

  const properties = {
    duration: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: true,
    canSwipe: false,
    arrows: false,
    infinite: true,
    easing: "ease",
    cssClass: "testimonial-slide-container",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
    ],
    indicators: (i) => (
      <div className="indicator">
        <div className="indicator__dot"></div>
      </div>
    )
  }

  return (
    <Section className={styles.academy__testimonials}>
      <Wrapper className={styles.academy__testimonials__wrapper}>
        <h1>What students are saying about the <span>academy</span></h1>
        <div className={styles.slides__container}>
          <Slide {...properties}>
            {testimonies.map((testimony, index) => (
              <div className={styles.testimony__eachSlide} key={index}>
                <div className={styles.testimony__header}>
                  <img src={testimony.image_url} alt="testifier" />
                  <div className={styles.testimony__header__content}>
                    <h3>{testimony.name}</h3>
                    <p>{testimony.role}</p>
                  </div>
                </div>
                <div className={styles.testimony__body}>
                  <p>{testimony.testimony}</p>
                </div>
              </div>
            ))}
          </Slide>
        </div>
      </Wrapper>
    </Section>
  )

}

export default AcademyTestimonies