import React from "react";
import classNames from "classnames";
import styles from "./about.module.scss";
import HeroImage from '../../assets/imgs/about-page.jpg';
import HeroImageMobile from '../../assets/imgs/about-page-mobile.jpg';
import successful from '../../assets/imgs/verified.svg';
import agile from '../../assets/imgs/agile-culture.jpg';
import successfulCard from '../../assets/imgs/successful-card.svg';
import agileCard from '../../assets/imgs/agile-card.jpg';
import worldClassCard from '../../assets/imgs/world-class-card.svg';
import worldClass from '../../assets/imgs/world-class-icon.svg';
import scalableCard from '../../assets/imgs/scalable-card.svg';
import Scalable from '../../assets/imgs/scalable-icon.svg';

import Location from '../../components/Location/Location';
import Section from "../../components/Wrappers/Section";
import Wrapper from "../../components/Wrappers/Wrapper";


const About = () => {
  return (
    <div className={styles.about}>
      <Section className={styles.about__section}>
        <Wrapper className={styles.about__hero}>
          <h1>We’re reimagining product development so every growing company can realize their full
            <span>potential.</span>
          </h1>
          <p>We focus on bringing digital products from validation to success, optimising our unique
            expertise to create a product that fulfills your target customers' needs
          </p>
        </Wrapper>
      </Section>
      <Section className={styles.about__image_container}>
        <div className={styles.about__image}>
          <img className={styles.about__image__lg} src={HeroImage} alt="A capture of enyata staffs in a group photograph" />
        </div>
      </Section>
      <div className={styles.about__image}>
        <img className={styles.about__image__mobile} src={HeroImageMobile} alt="A capture of enyata staffs in a group photograph" />
      </div>
      <div className={classNames(styles.about__whoweare, "padding")}>
        <div className={styles.about__whoweare__heading}>
          <h2>Who we are</h2>
          <p>A technology company providing engineering-as-a-service solutions at scale to organizations
            across multiple industries. We are a legion of product, engineering, and design experts
            helping organisations develop world-class products.
          </p>
        </div>
        <div className={styles.about__whoweare__body}>
          <h1>“We want to build a world where great ideas, products, and companies come to
            fruition quickly whilst achieving the required impact. Our work is to facilitate
            the process.”
          </h1>
          <p>Oladayo Oyelade - CTO, enyata</p>
        </div>
      </div>
      <Section className={styles.about__services}>
        <Wrapper className={styles.engineering_teams}>
          <div className={styles.engineering_structure}>
            <div className={styles.engineering_structure_left}>
              <div className={styles.engineering_structure_left_top}>
                <img src={successful} alt='' />
                <h5>Successful, diverse teams</h5>
              </div>
              <div className={styles.engineering_structure_left_bottom}>
                <p>Build fully functional, visually compelling web and mobile applications that represent your brand perfectly</p>
                <img src={successfulCard} alt='' />
              </div>
            </div>
            <div className={styles.engineering_structure_right}>
              <div className={styles.engineering_structure_right_top}>
                <img src={agile} alt='' />
                <h5>Agile culture</h5>
              </div>
              <div className={styles.engineering_structure_right_bottom}>
                <p>Design thinking and agile principles are at the core of how our teams operate.</p>
                <img src={agileCard} alt='' />
              </div>
            </div>
            <div className={styles.engineering_structure_right}>
              <div className={styles.engineering_structure_right_top}>
                <img src={worldClass} alt='' />
                <h5>World class team</h5>
              </div>
              <div className={styles.engineering_structure_right_bottom}>
                <p>Engineers at Enyata are technology leaders and domain experts in their fields.</p>
                <img src={worldClassCard} alt='' />
              </div>
            </div>
            <div className={styles.engineering_structure_right}>
              <div className={styles.engineering_structure_right_top}>
                <img src={Scalable} alt='' />
                <h5>Scalable teams</h5>
              </div>
              <div className={styles.engineering_structure_right_bottom}>
                <p>Augment your teams with our on-demand, high-quality engineering teams to build at scale.</p>
                <img src={scalableCard} alt='' />
              </div>
            </div>
          </div>
        </Wrapper>
        <Location />
      </Section>
    </div>
  );
};

export default About;
