import Actions from "../Types";

const initialState = {
  isLoading: false,
  success: false,
  posts: [],
  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_BLOG_POSTS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case Actions.GET_BLOG_POSTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        posts: action.payload,
        success: true,
        error: null,
      };
    case Actions.GET_BLOG_POSTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
