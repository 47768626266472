import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "../pages/About/About";
import Services from "../pages/Services";
import CaseStudy from "../pages/CaseStudy";
import Careers from "../pages/Careers";
import Kafene from "../pages/CaseStudy/Kafene";
import CareClick from "../pages/CaseStudy/CareClick";
import Volley from "../pages/CaseStudy/Volley";
import FiClub from "../pages/CaseStudy/FiClub";
import Letshego from "../pages/CaseStudy/Letshego";
import Home from "../pages/Home/Home";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "../pages/ContactUs/ContactUs";
import Academy from "../pages/Academy/Academy";
import Blog from "../pages/Community/Blog";
import Events from "../pages/Community/Events";
import Podcast from "../pages/Community/Podcast";
import AcademyContactUs from "../pages/Academy/ContactUs";
import SingleJob from "../pages/SingleJob/SingleJob";
import NotFound from "../components/NotFound/NotFound";

const AllRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:role" element={<SingleJob />} />
        <Route exact path="/case-studies" element={<CaseStudy />} />
        <Route exact path="/case-studies/kafene" element={<Kafene />} />
        <Route path="/case-studies/care-click" element={<CareClick />} />
        <Route exact path="/case-studies/volley" element={<Volley />} />
        <Route path="/case-studies/fi-club" element={<FiClub />} />
        <Route path="/case-studies/letshego" element={<Letshego />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/community/blog" element={<Blog />} />
        <Route path="/community/events" element={<Events />} />
        <Route path="/community/podcast" element={<Podcast />} />
        <Route path="/academy" element={<Academy />} />
        <Route path="/academy/contact" element={<AcademyContactUs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;
