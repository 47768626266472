import React, { useState } from "react";
import styles from "./community.module.scss";
import Section from "../../components/Wrappers/Section";
import Wrapper from "../../components/Wrappers/Wrapper";
import Landing from "../../components/Community/Landing";
import Introduction from "../../components/Community/Introduction";
import Webinar1 from "../../assets/imgs/webinar-effective-sales-technique.jpeg";
import Webinar2 from "../../assets/imgs/webinar-building-for-global-relevance.jpeg";
import Webinar3 from "../../assets/imgs/webinar-engineering-pipelines-for-developer-growth.png";
import Webinar4 from "../../assets/imgs/webinar-data-privacy.png";
import Webinar5 from "../../assets/imgs/webinar-from-concept-to-clients.jpeg";
import Webinar6 from "../../assets/imgs/enyata-youtube-tech-training-4.jpg";
import Webinar7 from "../../assets/imgs/enyata-youtube-webinar template-landscape-8.jpg";
import Webinar8 from "../../assets/imgs/enyata-youtube-webinar-template-landscape-7.jpg";
import Webinar9 from "../../assets/imgs/getting-started-with-opensource.jpg";
import Webinar10 from "../../assets/imgs/ama-sodiq.jpg";
import Webinar11 from "../../assets/imgs/fp-albert.jpg";
import Webinar12 from "../../assets/imgs/cv-oluwaseun.jpg";
import Webinar13 from "../../assets/imgs/faang.jpg";
import Webinar14 from "../../assets/imgs/business-fundamentals.jpg";
import Webinar15 from "../../assets/imgs/social-media-message.jpg";
import Webinar16 from "../../assets/imgs/enterprise-level-business.png";
import MediaImage1 from "../../assets/imgs/media1.png";
import MediaImage2 from "../../assets/imgs/event2.png";
import MediaImage3 from "../../assets/imgs/media3.png";
import MediaImage4 from "../../assets/imgs/event4.png";
import Webinar17 from "../../assets/imgs/overcoming-imposter-syndrome.jpg";
import Webinar18 from "../../assets/imgs/understanding-the-basics-of-pm.jpg";
import Webinar19 from "../../assets/imgs/principles-of-design-thinking.jpg";
import Webinar20 from "../../assets/imgs/using-motion-to-animate-with-purpose.jpg";
import Webinar21 from "../../assets/imgs/essential-skills-for a-quality-assurance Engineer.jpg";
import Webinar22 from "../../assets/imgs/software-design-patterns.jpg";
import Webinar23 from "../../assets/imgs/harnessing-technology-for-seamless-operations.jpg";
import Webinar24 from "../../assets/imgs/the-role-of-AI-in-product-marketing.jpg";
import Webinar25 from "../../assets/imgs/webinar-Unleashing Potential-Enyata in America.jpg";

export const eventsData = [
  {
    index: 28,
    title: "Unleashing Potential: Enyata in America",
    image: Webinar25,
    url: "https://youtube.com/watch?v=jjGHpYXoSEY",
  },
  {
    index: 27,
    title: "The Role of AI in Product Marketing",
    image: Webinar24,
    url: "https://youtu.be/-zPVAreoduo?feature=shared",
  },
  {
    index: 26,
    title: "Harnessing Technology for Seamless Operations",
    image: Webinar23,
    url: "https://youtu.be/BHGpNpZ7wRo?feature=shared",
  },
  {
    index: 25,
    title: "Software Design Patterns with Ifeoluwa",
    image: Webinar22,
    url: "https://youtu.be/rT3_qBXA9rc?feature=shared",
  },
  {
    index: 24,
    title: "Essential Skills for a Quality Assurance Engineer with Godson Nwankwo",
    image: Webinar21,
    url: "https://youtu.be/L23I3nQyYWQ?feature=shared",
  },
  {
    index: 23,
    title: "Using Motion Design to Animate with Purpose with Korede Ajibola",
    image: Webinar20,
    url: "https://youtu.be/5-9HNQONIs4?si=AzZ-4tRCwoIAuCja",
  },
  {
    index: 22,
    title: "The Principles of Design Thinking with Eratus Oluwatosin",
    image: Webinar19,
    url: "https://youtu.be/f5pGQFSoRBA?feature=shared",
  },
  {
    index: 21,
    title: "Understanding the Basics of Product Management with Temitope Odiahi",
    image: Webinar18,
    url: "https://youtu.be/7n9USubSeBM?feature=shared",
  },
  {
    index: 20,
    title: "Overcoming Imposter Syndrome with Tabitha Kavyu",
    image: Webinar17,
    url: "https://www.youtube.com/watch?v=vmoPBTh4GRE",
  },
  {
    index: 0,
    title:
      "Getting Started with Open Source and Making Your First Contribution with Ruth Ikegah",
    image: Webinar9,
    url: "https://youtu.be/wqCelbSkKNc",
  },
  {
    index: 1,
    title: "Community AMA with Sodiq Akinjobi",
    image: Webinar10,
    url: "https://youtu.be/eNerAXN2_7Y",
  },
  {
    index: 2,
    title: "Understanding Functional Programming with Albert Osei",
    image: Webinar11,
    url: "https://youtu.be/g6dWwIDiezw",
  },
  {
    index: 3,
    title: "Crafting a Perfect Engineering CV with Oluwaseun Odulana",
    image: Webinar12,
    url: "https://youtu.be/6pqW8-9K8fI",
  },
  {
    index: 4,
    title: "Securing a Job in a FAANG company with Chisom Nwokwu",
    image: Webinar13,
    url: "https://youtu.be/A92rQmAp2m0",
  },
  {
    index: 5,
    title:
      "Understanding Business Fundamentals That Investors Love with Roger Norton",
    image: Webinar14,
    url: "https://youtu.be/pShxzZOjPm0",
  },
  {
    index: 6,
    title: "Crafting Your Messages for Social Media",
    image: Webinar15,
    url: "https://youtu.be/XhJVE2LfdPo",
  },
  {
    index: 7,
    title: "Building an Enterprise-Level Business",
    image: Webinar16,
    url: null,
  },
  {
    index: 8,
    title: "Improve your CSS code without a framework",
    image: Webinar6,
    url: null,
  },
  {
    index: 9,
    title: "Building Enterprise Applications on Blockchain",
    image: Webinar7,
    url: null,
  },
  {
    index: 10,
    title: "Business Meets Tech",
    image: Webinar8,
    url: null,
  },
  {
    index: 11,
    title: "From concept to clients",
    image: Webinar5,
    url: null,
  },
  {
    index: 12,
    title: "Data Privacy and Protection",
    image: Webinar4,
    url: null,
  },
  {
    index: 13,
    title: "Engineering Pipelines for Developers",
    image: Webinar3,
    url: "https://www.youtube.com/watch?v=KL-rHZ08RHM",
  },
  {
    index: 14,
    title: "Building for Global Relevance",
    image: Webinar2,
    url: "https://www.youtube.com/watch?v=o0Z8aHCsJMs",
  },
  {
    index: 15,
    title: "Effective Sales techniques for business growth",
    image: Webinar1,
    url: "https://www.youtube.com/watch?v=9-N2h83obAk",
  },
  {
    index: 16,
    title: "Building in the Open",
    image: MediaImage1,
    url: null,
  },
  {
    index: 17,
    title: "Using continuous foresight",
    image: MediaImage2,
    url: null,
  },
  {
    index: 18,
    title: "Positioning yourself for Hybrid/Remote Work",
    image: MediaImage3,
    url: null,
  },
  {
    index: 19,
    title: "Measuring growth as an Engineer in the Tech Ecosystem",
    image: MediaImage4,
    url: null,
  },
];

const Events = () => {
  const [activeView, setActiveView] = useState("Events");

  return (
    <div className={styles.blog}>
      <Landing
        activeView={activeView}
        setActiveView={setActiveView}
        description="Events are a great opportunity to network, connect with other professionals and discover a new knowledge. Stay in the loop of upcoming events at Enyata community by subscribing now."
      />
      <Section className={styles.media__section}>
        <Wrapper className={styles.media}>
          <Introduction
            className={styles.introduction__events}
            title="See what’s coming up…"
            details="Our events are a defined avenue that features carefully-curated insights from renowned product leaders, experts, and innovators across the globe."
          />

          <div className={styles.media__content}>
            {eventsData.map((event, index) => (
              <div className={styles.image} key={index}>
                {event.url !== null ? (
                  <a
                    href={event.url}
                    title="Click to register"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={event.image} alt={event.title} key={index} />
                  </a>
                ) : (
                  <img src={event.image} alt={event.title} key={index} />
                )}
              </div>
            ))}
          </div>
        </Wrapper>
      </Section>
    </div>
  );
};

export default Events;
