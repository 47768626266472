import React from "react";
import { useNavigate } from "react-router-dom";
import Section from "../Wrappers/Section";
import Wrapper from "../Wrappers/Wrapper";
import styles from "../../pages/Community/community.module.scss";
import Button from "../../components/Button/Button";

const Landing = ({ activeView, setActiveView, description }) => {
  const navigate = useNavigate();

  const views = ["Blog", "Events", "Podcast"];

  const switchView = (view) => {
    const viewRoute = view?.toLowerCase();
    setActiveView(view);
    navigate(`/community/${viewRoute}`);
  };

  return (
    <Section className={styles.blogheader__section}>
      <Wrapper className={styles.blogheader}>
        <h1>Where tech talents come to grow</h1>
        <p>{description}</p>

        <div className={styles.subscribe}>
          {/* <Search onChange={() => {}} placeholder="Enter Email..." /> */}
          <a
            href="https://forms.gle/mGXHteei9hHj2HQJ8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>Subscribe</Button>
          </a>
        </div>

        <div className={styles.blog__titles}>
          {views.map((item, index) => (
            <div
              key={index}
              className={styles.title}
              style={{
                borderBottom: activeView === item ? "2px solid #481787" : "",
              }}
              onClick={() => switchView(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </Wrapper>
    </Section>
  );
};

export default Landing;
