import classNames from "classnames";
import styles from "../caseStudy.module.scss";
import KafeneLogo from "../../../assets/imgs/kafene-logo.svg";
import KafeneImg from "../../../assets/imgs/kafene-case-img.png";
import Reviewer from "../../../assets/imgs/kafene-reviewer.svg";
import Section from "../../../components/Wrappers/Section";
import Wrapper from "../../../components/Wrappers/Wrapper";

const KafeneCaseStudy = () => {
  return (
    <div>
      <Section>
        <Wrapper>
          <div className={styles.caseStudyPage}>
            <div className={styles.customer}>
              <img src={KafeneLogo} alt="Kafene logo" />
              <p className={styles.customer__info}>
                Kafene wants you to enjoy the financial flexibility you deserve with affordable
                lease-to-own options for furniture, appliances, electronics, tires and wheels,
                and more - for nearly all credit ranges and price points.
              </p>
            </div>
            <div
              className={classNames(
                styles.customer__platform,
                styles.customer__platform__green
              )}
            >
              <p>Platforms:</p>
              <p className={styles.name}>Merchant, Customer & Adminstrators Dashboard</p>
            </div>
          </div>
        </Wrapper>
      </Section>
      <Section className={styles.customerImg}>
        <Wrapper>
          <div className={styles.customerImg__img}>
            <img src={KafeneImg} alt="Kafene's application dashboard" />
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <div className={styles.caseStudyPage}>
            <h3 className={styles.customer__sectionHeader}>The Challenge</h3>
            <p className={styles.customer__info}>
              Kafene’s messaging was contingent on the promise that anyone could
              create an account and go as far as enabling payment in “in less than
              5 minutes”. This meant that whatever software solution we built needed
              to be optimised for speed, and more importantly, the vendor’s onboarding
              process should be at most four minutes fifty-nine seconds long.
            </p>
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <div className={styles.caseStudyPage}>
            <div className={styles.customer__solution}>
              <h3 className={styles.customer__sectionHeader}>The Solution</h3>
              <p className={styles.customer__info}>
                To deliver on the brand promise, a dedicated team was formed to oversee the project;
                acting as a technological partner. We took over the technology stack, hosting,
                and continuous deployment processes, testing iterations and making changes where needed. <br />
                We built a web platform - one that accounted for loan applications and commercial engagements
                between Merchants and Customers while focusing on achieving efficiency in payment processing,
                servicing, and underwriting. <br />
                At the end, the MVP was able to beat the 5-minute mark After a series of iterations,
                the MVP was a success and customers were actively applying for loans and purchasing
                items via the application. Kafene was now ready to scale and bring on more merchants.
                Kafene now boasts a robust inventory of about 10,000 users and still counting.

              </p>
            </div>
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <div className={styles.customer__review}>
            <h3>
              Our relationship with Enyata has been incredibly positive, and I
              would recommend them to any company. They are hands down one of
              the top development teams I've ever worked with.”
            </h3>
            <div className={styles.customer__reviewer}>
              <div className={styles.customer__reviewer__image}>
                <img src={Reviewer} alt="reviewer" />
              </div>
              <div className={styles.customer__reviewer__info}>
                <p className={styles.customer__reviewer__name}>James Schuler</p>
                <p className={styles.customer__reviewer__title}>CTO, Kafene</p>
              </div>
            </div>
          </div>
        </Wrapper>
      </Section>
    </div>
  );
};

export default KafeneCaseStudy;
