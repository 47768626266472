import styles from "./tracks.module.scss";
import { useMemo, useState } from 'react'
import classNames from 'classnames'

const learningTracks = [
  {
    name: 'Software Engineering ',
    title: 'Java Programming and Software Engineering',
    description: `
      Take your first step towards a career in software development with this introduction to Java—one of the most in-demand programming languages and the foundation of the Android operating system. 
      /n 
      Designed for beginners, this Specialization will teach you core programming concepts and equip you to write programs to solve complex problems.`,
    image: 'software-development',
    id: '238hgsd'
  },
  {
    name: 'Product Management',
    title: 'Product Ideation and Management Specialization',
    description: `
      Learn how to truly know your target customer, your customer’s underserved needs, your value proposition, your product feature set, and your user experience, then integrate this knowledge into product and market requirements and positioning plans.
      /n
      Learners will create a comprehensive set of designs and strategies for a new product or service, or an improvement on an existing product or service.`,
    image: 'product-management',
    id: 'wejdhb2'
  },
  {
    name: 'Product Design',
    title: 'Innovation Through Design: Think, Make, Break, Repeat',
    description: `
      The evolution of design has seen it become a discipline no longer limited to the concerns of a singular, specific domain and develop to become a pathway for solving complex, nonlinear problems. Design is becoming a capability-enhancing skill, equipping people with the ability to deal with uncertainty, complexity and failure. 
      /n
      We demonstrate how you can use design as a way of thinking to provide strategic and innovative advantage within your profession.`,
    image: 'product-design',
    id: 'lekyrgu'
  },
  {
    name: 'Data Analysis',
    title: 'Contemporary Data Analysis: Survey and Best Practices',
    description: `
      Despite a large variety of different courses on analytics, the courses that offer a broad overview of the field are rare. From practice of teaching statistics, it became clear that it is difficult for learners to put together a broad field map if they have taken only a few of the different topics on analytical tools. 
      /n
      As a result, they do not see the overall picture of everything that the field of data analysis has to offer.`,
    image: 'data-analysis',
    id: 'diuygw'
  }
]

const Tracks = () => {
  const [selectedTrack, setSelectedTrack] = useState('238hgsd')
  const [currentTrack, setCurrentTrack] = useState(null)
  useMemo(() => {
    const track = learningTracks.find(({ id }) => id === selectedTrack)
    setCurrentTrack(track)
  }, [selectedTrack])
  // break description into paragraphs
  const formatDescription = description => {
    return description && description.split('/n').map(paragraph => {
      return <p key={Math.random()}>{paragraph} <br /><br /></p>
    })
  }
  return (
    <section className='container'>
      <div className={styles.tracksSlider}>
        <div className={styles.tracksSlider__navigation}>
          <div className={styles.tracksSlider__navigation__container}>
            {learningTracks.map(({ name, id }) => (
              <TrackButton
                name={name}
                key={'nav-' + id}
                isActive={selectedTrack === id}
                setSelectedTrack={() => setSelectedTrack(id)} />
            ))}
          </div>
        </div>
        <div className={styles.trackItem}>
          <div className={styles.trackItem__text}>
            <h4>{currentTrack?.title}</h4>
            {formatDescription(currentTrack?.description)}
          </div>
          <div className={styles.trackItem__media}>
            <img src={`/learning-tracks/${currentTrack?.image}.png`} alt="Software Engineering" />
          </div>
        </div>

      </div>
    </section>
  )
}

export default Tracks;


const TrackButton = ({ name, isActive, setSelectedTrack }) => (
  <button
    className={classNames({
      [styles.track__button]: true,
      [styles.track__button__active]: isActive
    })}
    onClick={setSelectedTrack}>
    {name}
  </button>
)