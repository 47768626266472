import React, { useEffect } from "react";
import { useForm } from 'react-hook-form'
import { connect } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import classNames from "classnames";
import styles from "./academy.module.scss";
import Section from "../../components/Wrappers/Section";
import Wrapper from "../../components/Wrappers/Wrapper";
import ContactUs from "../../assets/imgs/academy-contact.jpg";
import AcademyTestimonies from '../../components/AcademyTestimonies/AcademyTestimonies';

import { academyContactUs } from "../../redux/Actions/AuthActions";

const AcademyContactUs = ({ academyContactUs, isLoading, success }) => {

  const schema = yup.object().shape({
    name: yup.string().required('Fist Name is required.'),
    email: yup.string().email().required('Email is required.'),
    about: yup.string().required('Tell us why you want to join enyata academy.'),
  });

  const { handleSubmit, register, formState: { errors }, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const applicationIsOpen = false;
  const cohort = 7;

  useEffect(() => {
    reset({
      name: '',
      email: '',
      about: '',
      last_name: '',
      phone: '',
      gender: '',
      state_of_origin: '',
      state_of_residence: '',
      date_of_birth: '',
      preferred_track: ''
    })
  },
    // eslint-disable-next-line
    [success])

  return (
    <>
      <Section>
        <Wrapper>
          <div className={styles.academy__contactus__hero}>
            <div className={styles.academy__contactus__hero__left}>
              <h1>Building skillsets that will power innovations</h1>
              <p>Academy participants are mentored by senior engineers at Enyata, and they get to understudy our experts working on global projects.</p>
              <img src={ContactUs} alt="two female engineers looking at a white marker board to plan a sprint" />
            </div>
            <div className={styles.academy__contactus__hero__right}>
              {
                applicationIsOpen ?
                  <div className={styles.academy__contactus__hero__right__form}>
                    <form onSubmit={handleSubmit(academyContactUs)}>

                      <div className={classNames(styles.input__form__grid, styles.input__form__grid__top)}>
                        <div className={styles.input__form__grid__child}>
                          <label htmlFor="first name">First Name</label>
                          <input
                            type="text"
                            className={errors.name?.message ? styles.form_error_border : ''}
                            {...register('name')} />
                          <p className={styles.form_error}>{errors.name?.message}</p>
                        </div>
                        <div className={styles.input__form__grid__child}>
                          <label htmlFor="last name">Last Name</label>
                          <input type="text"
                            {...register('last_name')} />
                        </div>
                      </div>
                      <div className={styles.input__form__full}>
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          className={errors.email?.message ? styles.form_error_border : ''}
                          {...register('email')} />
                        <p className={styles.form_error}>{errors.email?.message}</p>
                      </div>
                      <div className={styles.input__form__grid}>
                        <div className={styles.input__form__grid__child}>
                          <label htmlFor="phone">Phone No.</label>
                          <input
                            type="tel"
                            {...register('phone')} />
                        </div>
                        <div className={styles.input__form__grid__child}>
                          <label htmlFor="">Gender</label>
                          <input
                            type="text"
                            {...register('gender')} />
                        </div>
                      </div>
                      <div className={styles.input__form__grid}>
                        <div className={styles.input__form__grid__child}>
                          <label htmlFor="">State of Origin</label>
                          <input
                            type="text"
                            {...register('state_of_origin')} />
                        </div>
                        <div className={styles.input__form__grid__child}>
                          <label htmlFor="">State of Residence</label>
                          <input
                            type="text"
                            {...register('state_of_residence')} />
                        </div>
                      </div>
                      <div className={styles.input__form__grid}>
                        <div className={styles.input__form__grid__child}>
                          <label htmlFor="">Date of Birth</label>
                          <input
                            type="text"
                            {...register('date_of_birth')} />
                        </div>
                        <div className={styles.input__form__grid__child}>
                          <label htmlFor="">Preferred Track</label>
                          <input
                            type="text"
                            {...register('preferred_track')} />
                        </div>
                      </div>
                      <div className={styles.input__form__full}>
                        <label htmlFor="about">Why do you want to join the enyata academy?</label>
                        <textarea
                          name="about"
                          id="reason-of-joining"
                          placeholder='Tell us more'
                          className={errors.about?.message ? styles.form_error_border : ''}
                          {...register('about')} />
                        <p className={styles.form_error}>{errors.about?.message}</p>
                      </div>
                      <button
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                  </div> :
                  <div className={styles.academy__contactus__hero__right__closed}>
                    <p>Application for Cohort {cohort} is not yet open. <br />
                      Please check back later.</p>
                  </div>
              }
            </div>
          </div>
        </Wrapper>
      </Section>

      <AcademyTestimonies />
    </>
  )
}

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  success: state.auth.success,
});

const mapDispatchToProps = (dispatch) => ({
  academyContactUs: (payload) => {
    dispatch(academyContactUs(payload))
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AcademyContactUs);