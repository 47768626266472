import React from "react";
import styles from "./caseStudyCard.module.scss";
import ReadMore from "../../assets/icons/right-arrow.svg";
import { Link } from "react-router-dom";

const caseStudyCard = ({ caseStudies }) => {
  return (
    <div className={styles.case__studies}>
      {caseStudies &&
        caseStudies.map((sample, index) => (
          <div key={index} className={styles.case__study}>
            <img src={sample.image} alt="case study pic" />
            <div className={styles.case__study__text}>
              <p>
                <span>{sample.title}</span> - {sample.info}
              </p>
              {sample?.url && (
                <Link to={sample.url}>
                  <span>Read Case Study</span>
                  <img src={ReadMore} alt="read" className={styles.icon} />
                </Link>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default caseStudyCard;
