import classNames from "classnames";
import styles from "../caseStudy.module.scss";
import CareClickLogo from "../../../assets/imgs/careclick.png";
import CareClickImg from "../../../assets/imgs/careclick-case-img.png";
import Section from "../../../components/Wrappers/Section";
import Wrapper from "../../../components/Wrappers/Wrapper";

const CareClickCaseStudy = () => {
  return (
    <div>
      <Section>
        <Wrapper>
          <div className={styles.caseStudyPage}>
            <div className={styles.customer}>
              <img src={CareClickLogo} alt="CareClick logo" />
              <p className={styles.customer__info}>
                CareClick is a pioneering telemedicine company that is bridging the
                gap in provision of quality healthcare solutions & services across Africa.
              </p>
            </div>
            <div
              className={classNames(
                styles.customer__platform,
                styles.customer__platform__blue
              )}
            >
              <p>Platforms:</p>
              <p className={styles.name}>Android & IOS</p>
            </div>
          </div>
        </Wrapper>
      </Section>
      <Section className={styles.customerImg}>
        <Wrapper>
          <div className={styles.customerImg__img}>
            <img src={CareClickImg} alt="CareClick's application dashboard" />
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <div className={styles.caseStudyPage}>
            <h3 className={styles.customer__sectionHeader}>The Challenge</h3>
            <p className={styles.customer__info}>
              In trying to create remote healthcare services, CareClick needed mobility.
              And also given the importance of healthcare, it also needed to be efficient
              because even the littlest bottleneck could have dire consequences. <br />
              It was a delicate project and it was important that we approached each stage
              and implementation with equal priority.
            </p>
          </div>
        </Wrapper>
      </Section>
      <Section>
        <Wrapper>
          <div className={styles.caseStudyPage}>
            <div className={styles.customer__solution}>
              <h3 className={styles.customer__sectionHeader}>The Solution</h3>
              <p className={styles.customer__info}>
                We got working on a mobile platform so that users can get unlimited access
                to quality healthcare services. We took disjointed processes
                (scheduling, medical forms, provider messaging, notifications) on multiple platforms
                and combined them into a single application to remove the burden on the patient <br />
                There, they gain access to medical consultations, prescriptions, even remote monitoring
                without having to worry about the bottlenecks of offline clinic experience. We also included
                correspondence features. Users can now have conversations with professionals
                (text or call - audio & video). We also built a web application offering the same benefits. <br />
                In the end, CareClick was able to provide remote & convenient means of connecting our
                subscribers to quality & affordable healthcare providers for themselves and their families.

              </p>
            </div>
          </div>
        </Wrapper>
      </Section>
    </div>
  );
};

export default CareClickCaseStudy;
