import styles from "./academy.module.scss";
import Section from "../../components/Wrappers/Section";
import Wrapper from "../../components/Wrappers/Wrapper";
import Button from "../../components/Button/Button";
// import arrowRight from "../../assets/icons/arrow-right.svg";
import Brainstorm from "../../assets/imgs/brainstorm.jpg";
import LearnTech from "../../assets/imgs/learn-tech.jpg";
import LearnProduct from "../../assets/imgs/learn-product.jpg";
import LearnAcademy from "../../assets/imgs/learn-academy.jpg";
import LineVectorArt from "../../assets/imgs/line-art-human.svg";
import Tracks from '../../components/Tracks/Tracks';
import AcademyTestimonies from '../../components/AcademyTestimonies/AcademyTestimonies';

import { Link } from "react-router-dom";


const Academy = () => {
  return (
    <div className={styles.academy}>
      <Section className={styles.academy__hero__section}>
        <Wrapper className={styles.academy__hero__wrapper}>
          <div className={styles.academy__hero__wrapper__left}>
            {/* <div className={styles.academy__hero__wrapper__left__title}>
              <span>New</span>
              Applications to the Q1 Cohort is on
              <img src={arrowRight} alt="arrow right icon" />
            </div> */}
            <h1>Empowering young africans with skills for tomorrow.</h1>
            <p>The Enyata Academy is an intensive, free, 14-week software engineering program for anyone looking to launch a career in software.</p>
            <Link to="/academy/contact">
              <Button>Apply Now</Button>
            </Link>
          </div>
          <div className={styles.academy__hero__wrapper__right}>
            <img src={Brainstorm} alt="arrow right icon" />
          </div>
        </Wrapper>
      </Section>

      <Section className={styles.academy__learn__section}>
        <Wrapper className={styles.academy__learn__section__wrapper}>
          <div className={styles.academy__learn__section__wrapper__content}>
            <h1>Learn from the best, work with the best</h1>
            <p>Join a growing community of technologists learning and building skillsets that will power the innovations of the future</p>
          </div>
        </Wrapper>
      </Section>

      <Section>
        <Wrapper>
          <Tracks />
        </Wrapper>
      </Section>

      <Section className={styles.academy__build__section}>
        <Wrapper className={styles.academy__build__section__wrapper}>
          <h1>We are building the next generation of techprenuers</h1>
          <p>Academy participants are mentored by senior engineers at Enyata, and they get to understudy our experts working on global projects.</p>
          <div className={styles.academy__build__section__wrapper__images}>
            <img src={LearnTech} alt="two engineers sitting, looking into the screen of a macbook pro" />
            <img src={LearnProduct} alt="a project manager showin an engineer through a product review on a white marker board sitting" />
            <img src={LearnAcademy} alt="two academy graduates looking at a board for some product information" />
          </div>
        </Wrapper>
      </Section>

      <Section className={styles.academy__join__section}>
        <Wrapper className={styles.academy__join__section__wrapper}>
          <div>
            <h1>Join our community of over <span>3000+</span> learners</h1>
            <Link to="/academy/contact">
              <span>Get Started</span>
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1468_11809)">
                  <path d="M18 5.99756L15.885 8.11256L24.255 16.4976H6V19.4976H24.255L15.885 27.8826L18 29.9976L30 17.9976L18 5.99756Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_1468_11809">
                    <rect width="36" height="36" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Link>
          </div>
          <div>
            <img src={LineVectorArt} alt="line art representation of a person sitting in front of a computer" />
          </div>
        </Wrapper>
      </Section>

      <AcademyTestimonies />
    </div>
  )
}

export default Academy;