const readTimeEstimate = require("read-time-estimate");

export const shortenStr = (str, maxLen) => {
  if (str?.length <= maxLen) return str;
  return str?.substr(0, str?.lastIndexOf(" ", maxLen));
};

export const convertToWords = (node, maxLen) => {
  let tag = document.createElement("div");
  tag.innerHTML = node;
  node = tag.innerText;
  return node && shortenStr(node, maxLen);
};

export const convertToText = (str, maxLen) => {
  const words = str && convertToWords(str);

  return words && shortenStr(words, maxLen);
};

export const calculateReadTime = (str) => {
  const { humanizedDuration, duration } = readTimeEstimate(str, 275, 12, 500, [
    "img",
    "Image",
  ]);

  if (duration < 1) {
    return "1 min read";
  } else {
    const readTime = humanizedDuration && `${humanizedDuration} read`;
    return readTime && readTime.replace("minutes", "min");
  }
};

export const removeSpecialChar = (str) => {
  return str && str.replace(/-/g, " ");
};
