import { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./loader.module.scss";
import Logo from "../../assets/imgs/enyata-logo.svg";

const Preloader = () => {
    const [showBlue, setShowBlue] = useState(false);
    const [showPurple, setShowPurple] = useState(false);
    const [showWhite, setShowWhite] = useState(false);
    const [showLogo, setShowLogo] = useState(false);
    const [fadeout, setFadeOut] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowBlue(true);
        }, 0);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowPurple(true);
        }, 700);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowWhite(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLogo(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setFadeOut(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={classNames(styles.preloader__container, fadeout && styles.fadeout)}>
            <span className={classNames(styles.blue__slide, showBlue && styles.showBlue)}></span>
            <span className={classNames(styles.purple__slide, showPurple && styles.showPurple)}></span>
            <span className={classNames(styles.white__slide, showWhite && styles.showWhite)}></span>
            <span className={classNames(styles.preloader__logo, showLogo && styles.showLogo)}>
                <img src={Logo} alt="logo" />
            </span>
        </div >
    );
};

export default Preloader;