import classNames from "classnames";
import React from "react";

const Wrapper = ({ className, children, style }) => {
  return (
    <div className={classNames(className, "maxWidth")} style={style}>
      {children}
    </div>
  );
};

export default Wrapper;
