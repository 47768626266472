import React from "react";
import { Link } from "react-router-dom";
import styles from "../../pages/Home/home.module.scss";
import Forward from "../../assets/imgs/arrow-forward.svg";
import Wrapper from "../Wrappers/Wrapper";
import Section from "../Wrappers/Section";
import { eventsData } from "../../pages/Community/Events";

const Events = () => {
  const webinars = eventsData?.slice(0, 3);

  return (
    <Section className={styles.blog__section}>
      <Wrapper className={styles.blog}>
        <div className={styles.home__header}>
          <h2>Events</h2>
          <p>
            Join our webinars and live training sessions to learn how to build
            your skills and scale your business.
          </p>
        </div>

        <div className={styles.events}>
          {webinars.map((event, index) => (
            <div className={styles.events__image}>
              {
                event.url !== null ?
                  <a

                    href={event.url}
                    title="Click to register"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={event.image} alt={event.title} key={index} />
                  </a>
                  :
                  <img src={event.image} alt={event.title} key={index} />
              }
            </div>
          ))}
        </div>

        <Link to="/community/events" className={styles.home__link}>
          <span>Check out our events</span>
          <img src={Forward} alt="read" />
        </Link>
      </Wrapper>
    </Section>
  );
};

export default Events;
