import classNames from "classnames";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./navbar.module.scss";
import Logo from "../../assets/imgs/enyata-logo.svg";
import WhiteLogo from "../../assets/imgs/enyata-logo-white.svg";

const Navbar = ({ theme = "primary" }) => {
  const [clicked, setClicked] = useState(false);
  const { pathname } = useLocation();

  const routes = [
    {
      name: "About Us",
      url: "/about-us",
    },
    {
      name: "Case study",
      url: "/case-studies",
    },
    {
      name: "Academy",
      url: "/academy",
    },
    {
      name: "Community",
      url: "/community/blog",
    },
    {
      name: "Contact Us",
      url: "/contact-us",
    },
  ];

  const communityUrl = pathname.includes("/community");

  return (
    <section
      className={classNames(
        styles.navbar__section,
        pathname !== '/' && "padding",
        styles[`navbar__${theme}`]
      )}
      style={{ background: clicked && "#481787" }}
    >
      <div className={classNames(styles.navbar, "maxWidth")}>
        <a href="/">
          <div className={styles.navbar__left}>
            <img src={clicked ? WhiteLogo : Logo} alt="logo" />
          </div>
        </a>
        <input type="checkbox" id="nav-check" className={styles.nav__check} />

        <div className={styles.navbar__right}>
          {routes.map((item, index) => (
            <NavLink
              key={index}
              to={item.url}
              className={({ isActive }) =>
                isActive || (communityUrl && item.url.includes("/community"))
                  ? styles.navactive
                  : ""
              }
            >
              {item.name}
            </NavLink>
          ))}
        </div>

        <label
          htmlFor="nav-check"
          onClick={() => setClicked(!clicked)}
          className={styles.navbar__mid}
        ></label>

        <div className={styles.navbar__dropdown}>
          {routes.map((item, index) => (
            <a href={item.url} key={index}>
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Navbar;
